import styled from "styled-components";

import { Flex, Text } from '@pancakeswap/uikit';

interface PropsContainerContract {
    onClick?: () => void;
    title?: string;
    description?: string;
}

const ContainerContract = ({
    onClick,
    title = "Bằng cách “Xác nhận niêm yết” tôi đồng ý với",
    description = "hợp đồng chuyển nhượng chứng chỉ quỹ"
}: PropsContainerContract) => {
    return (
        <CsFlexAgree>
            <Text>{title}</Text>
            <CsLink
                isClick={!!onClick}
                onClick={onClick && onClick}
            >
                {description}
            </CsLink>
        </CsFlexAgree>
    )
};


const CsLink = styled.div<{ isClick: boolean }>`
    text-decoration: underline;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primaryBright};
    font-size: 15px;
    cursor: ${({ isClick }) => isClick ? "pointer" : "auto"};
    @media screen and (max-width: 600px){
            font-size: 14px;
    }
`


export const CsFlexAgree = styled(Flex)`
    width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        display: none;
    }

    @media screen  and (max-width: 600px){
        line-height: 20px;
    }
    ${Text} {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.text};
        @media screen and (max-width: 600px){
            font-size: 14px;
        }
    }
`

export default ContainerContract;