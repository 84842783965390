import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { useDispatch, useSelector } from 'react-redux';

import { UseActiveCCQ } from 'config/constants/ccqSupport/constants';
import contracts from 'config/constants/contracts';
import { renderTokenVNDCByChain } from 'hooks/useRenderTokenByChain';
import { getAddress } from 'utils/addressHelpers';
import { renderToken } from 'utils/renderMarketplaceAddress';
import { AppDispatch, AppState } from '../index';
import { actionDispatchListTerm, listItemHomeAction } from './actions';
import { fetchBalanceOf, fetchDataUser, fetchEtfInfo, fetchGetMyAsset, fetchGetTotalItems, fetchInterVestTermRate, fetchListTerm, fetchUserBalanceOf, } from './fetchData';

export const UseCoreListHome = (tokenAddress: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const { messageHash, payItem } = GetDataListsHome();
    const tokenVndc = renderTokenVNDCByChain(chainId);
    const token = renderToken(chainId);
    const contractMarketplaceAddress = getAddress(contracts?.marketplace, chainId);

    useSlowRefreshEffect(() => {
        (async () => {
            if (account?.length) {
                const activeCCQ = await UseActiveCCQ(chainId);
                const [resultEtfInfo, listIntervestRate, dataUser, getTotalItems] = await Promise.all(
                    [
                        fetchEtfInfo(account, activeCCQ, chainId),
                        fetchInterVestTermRate(activeCCQ, chainId),
                        fetchDataUser(activeCCQ, tokenAddress, account, chainId),
                        fetchGetTotalItems(contractMarketplaceAddress, chainId)
                    ]
                )
                const listUserAsset = await fetchGetMyAsset(resultEtfInfo, account, chainId);
                const { listItemHome } = await fetchBalanceOf(listUserAsset?.listUserAsset, resultEtfInfo, listIntervestRate?.listIntervestRate, dataUser?.listDataUser, account, chainId);
                const userBalanceOf = await fetchUserBalanceOf(tokenVndc.address, account, chainId);
                dispatch(listItemHomeAction({ listItemHome, totalDistributor: resultEtfInfo?.length, userBalanceOf }));
            } else {
                dispatch(listItemHomeAction({ listItemHome: [], totalDistributor: 0, userBalanceOf: 0 }));
            }
        })();
    }, [account, chainId, dispatch, token.address, tokenVndc.address, messageHash, tokenAddress, contractMarketplaceAddress])

    useSlowRefreshEffect(() => {
        (async () => {
            if (payItem) {
                const result: any = await fetchListTerm(payItem, chainId);
                dispatch(actionDispatchListTerm(result));
            }
        })();
    }, [chainId, dispatch, account, payItem, messageHash]);
};


export const GetDataListsHome = () => {
    const dataListHome = useSelector<AppState, AppState['homeFundgo']>((state) => state.homeFundgo)
    return dataListHome
}
