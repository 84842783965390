import moment from "moment";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

import { Flex, NoticeIcon, PlayIcon, Text, useModal, useTooltip } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";

import { Items } from "state/homeFundgo/type";

import PayModal from "components/PayModal/PayModal";

import { PAY_DAY_NEXT_PROFIT, UNITDATE } from "config";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchPayItem } from "state/homeFundgo/actions";

import { CustomButton } from "components/CustomButton";
import { LightTooltip } from "components/ToolTip";
import {
    ContainerRowMobile,
    CsCellMobile,
    CsTextDone,
    MobileCellLarge,
    MobileCellSmall, StyCellSmall, StyCellSTT, StyCellSttName
} from "./style";

const Row: React.FC<Items> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const windowSize = GetWindowSize();
    const [onPayModal] = useModal(
        <PayModal
            props
            windowSize={windowSize}
            title="Thông tin chứng chỉ quỹ"
        />,
        true,
        true,
        `modal_payment_${props?.nftAddress}`
    );

    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <CsTextTootip style={{ color: "red" }}>Còn {props.dayDiff} đến {UNITDATE} trả lợi suất kế tiếp</CsTextTootip>,
        { placement: "bottom-end", tooltipOffset: [20, 10] }
    );

    const color = !props.isPay && Number(props.dayDiff) > 5 ? "text" : !props.isPay && Number(props.dayDiff) < 5 ? "red" : "text";

    const ButtonPay = () => {
        return (
            !props.isPay ? (
                <CustomButton
                    disabled={!props.isApproveInterVestPayed}
                    onClick={() => {
                        dispatch(actionDispatchPayItem({ nftAddress: props.nftAddress }));
                        onPayModal();
                    }}
                    isWarning={!props.isPay && Number(props.dayDiff) <= 5}
                    variant="secondary"
                >
                    Thanh toán
                </CustomButton>
            ) : (
                <CustomButton disabled={props.isPay} variant="secondary">
                    Đã trả lợi nhuận
                </CustomButton>
            )
        )
    };

    return (
        <Flex width="100%" height="auto">
            {windowSize > 1000 ? (
                <ContainerRow>
                    <StyCellSttName>
                        <Link passHref href={`/fungo/${props.nftAddress}`} >
                            <a href={`/fungo/${props.nftAddress}`}>
                                <CsPlayIcon isWarning={!props.isPay && Number(props.dayDiff) <= 5} style={{ cursor: "pointer" }} />
                            </a>
                        </Link>
                        <Text color={color}>
                            <LightTooltip title={props?.ccqName} showIcon={false}>
                                <p
                                    style={{
                                        width: "80px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {props.ccqName}
                                </p>
                            </LightTooltip>
                        </Text>
                    </StyCellSttName>
                    <StyCellSttName>
                        <LightTooltip title={props?.distributor} showIcon={false}>
                            <Text color={color}
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    width: "80px",
                                }}
                            >
                                {props.distributor}
                            </Text>
                        </LightTooltip>
                    </StyCellSttName>
                    <StyCellSmall>
                        <Text color={color}>{`${Number(props.profit) * 100}%`}</Text>
                    </StyCellSmall>
                    <StyCellSmall style={{ width: "8%" }}>
                        <Text color={color}>{`${props.intervestTerm} tháng`}</Text>
                    </StyCellSmall>
                    <StyCellSTT>
                        <Text color={color}>{moment(props.paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT)}</Text>
                    </StyCellSTT>
                    <StyCellSmall style={{ width: "7%" }}>
                        <Text color={color}>{props.dayDiff} {UNITDATE}</Text>
                    </StyCellSmall>
                    <StyCellSTT style={{ width: "12%" }}>
                        <Text color={color}>
                            {Number(props.totalSupply).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </StyCellSTT>
                    <StyCellSmall style={{ width: "8%" }}>
                        <Text color={color}>
                            {Number(props.denominations).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </StyCellSmall>
                    <StyCellSTT style={{ width: "10%" }}>
                        <Text color={color}>
                            {props.totalPrice.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT>
                        <Text color={color}>
                            {props.currentYield.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT>
                        <Text color={color}>
                            {props.currentYield.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT>
                        <CsText color={color} isOnSale={props.isPay}>
                            {!props.isPay && Number(props.dayDiff) <= 5 ? (
                                <>
                                    <ReferenceElement ref={targetRef}>
                                        <NoticeIcon />
                                    </ReferenceElement>
                                    <>{tooltipVisible && tooltip}</>
                                </>
                            ) : (
                                <> {props.isPay && "Đã thanh toán"} </>
                            )}
                        </CsText>
                    </StyCellSTT>
                    <Flex width="13%">
                        <ContainerButton>
                            <ButtonPay />
                        </ContainerButton>
                    </Flex>
                </ContainerRow>
            ) : (
                <ContainerRowMobile>
                    <Flex width="100%" height="32px" alignItems="center">
                        <MobileCellLarge>
                            <Flex width="100%" height="100%" justifyContent="flex-start" alignItems="center">
                                <Link passHref href={`/fungo/${props.nftAddress}`}>
                                    <PlayIcon color="textSubtle" style={{ cursor: "pointer" }} />
                                </Link>
                                <Flex ml="5px" alignItems="center">
                                    <Text bold>{props.ccqName}</Text>
                                </Flex>
                            </Flex>
                        </MobileCellLarge>
                        <MobileCellSmall>
                            <Text textAlign="center" bold color={color}>
                                {props.dayDiff} {UNITDATE}
                            </Text>
                        </MobileCellSmall>
                        <MobileCellSmall style={{ position: "relative", right: "20px" }}>
                            <Text textAlign="center" bold>{`${Number(props.profit) * 100}%`}</Text>
                        </MobileCellSmall>
                        <MobileCellSmall>
                            <Flex width="100%" justifyContent="flex-end" alignItems="center" style={{ gap: "6px" }}>
                                <CsTextPrice>
                                    {Number(props.totalPrice).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </CsTextPrice>
                                <img
                                    src="/images/coins/vndc.png"
                                    alt="logo"
                                    style={{ width: "24px", height: "24px" }}
                                />
                            </Flex>
                        </MobileCellSmall>
                    </Flex>
                    <Flex width="100%" height="32px" alignItems="center" justifyContent="space-between">
                        <CsCellMobile
                            width="50%"
                            paddingLeft="1.5rem"
                            onClick={() => {
                                onPayModal();
                                dispatch(actionDispatchPayItem({ nftAddress: props.nftAddress }));
                            }}
                        >
                            {!props.isPay && Number(props.dayDiff) <= 5 ? (
                                <>
                                    <ReferenceElement ref={targetRef}>
                                        <NoticeIcon />
                                    </ReferenceElement>
                                    <>{tooltipVisible && tooltip}</>
                                </>
                            ) : (
                                <> {props.isPay && <CsTextDone>Đã trả lợi nhuận</CsTextDone>} </>
                            )}
                        </CsCellMobile>
                        <CsCellMobile width="30%">
                            <Flex width="100%" justifyContent="flex-start" alignItems="center" style={{ gap: "6px" }}>
                                <MobileTotalSuply>
                                    {Number(props.totalSupply).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </MobileTotalSuply>
                            </Flex>
                        </CsCellMobile>
                        <ButtonPay />
                    </Flex>
                </ContainerRowMobile>
            )}
        </Flex>
    );
};

export default Row;


const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
const CsPlayIcon = styled(PlayIcon) <{ isWarning: boolean }>`
    fill: ${({ theme, isWarning }) => (isWarning ? theme.colors.red : theme.colors.textSubtle)};
`;
const ReferenceElement = styled.div`
    display: inline-block;
    cursor: pointer;
`;

const ContainerRow = styled(Flex)`
    width: 100%;
    padding: 0px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 900px) {
        height: auto;
        flex-direction: column;
        gap: 12px;
        border: 1px solid #e8e5e5;
        background-color: transparent;
    }
    @media screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const CsText = styled(Text) <{ isOnSale: boolean }>`
    color: ${({ theme, isOnSale }) => (isOnSale ? "#81D401" : theme.colors.primaryBright)} !important;
    font-weight: bold;
`;
const CsTextPrice = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    text-align: right;
    font-weight: 600;
    @media screen and (min-width: 1000px) and (max-width: 1280px) {
        font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
        width: auto;
        font-size: 16px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.text};
    }
    @media screen and (max-width: 600px) {
        text-align: right;
        font-size: 14px;
    }
`;

const MobileTotalSuply = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 600 !important;
    @media screen and (min-width: 1000px) and (max-width: 1280px) {
        font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
        text-align: initial;
        width: auto;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.text};
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
const ContainerButton = styled(Flex)`
    width: 100%;
    @media screen and (max-width: 1000px) {
        justify-content: flex-start;
    }
`;
