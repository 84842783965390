import { useTranslation } from "@pancakeswap/localization"
import { useToast } from "@pancakeswap/uikit"
import { ToastDescriptionWithTx } from "components/Toast"
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice"
import { useWrapped } from "hooks/useContract"
import { useUserRejected } from "hooks/useUserRejected"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "state"
import { actionsTransactionHash } from "state/manage/actions"

export const useAdvance = (nftAddress: string, onDismiss: any, paydayNextProfit: number) => {
    const { handleRejected } = useUserRejected();
    const { toastSuccess, toastError } = useToast()
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
    const { t } = useTranslation()
    const wrapAddressContract = useWrapped(nftAddress)
    const [pendingTxAdvance, setPendingTxAdvance] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const handleAdvance = useCallback(async () => {
        setPendingTxAdvance(true)
        try {
            const tx = await callWithMarketGasPrice(wrapAddressContract, 'payAdvanceIntervest', [paydayNextProfit])
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(
                    t('Tạm ứng thành công'),
                    <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                )
                setPendingTxAdvance(false)
                dispatch(actionsTransactionHash({ transactionHash: receipt.transactionHash }))
                onDismiss()
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Đã có lỗi xảy ra'), t('Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !'))
            }
        } catch (e) {
            handleRejected(e)
            setPendingTxAdvance(false)
        }
    }, [callWithMarketGasPrice, dispatch, handleRejected, onDismiss, paydayNextProfit, t, toastError, toastSuccess, wrapAddressContract])

    return { handleAdvance, pendingTxAdvance }
}
