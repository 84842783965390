
import { Tooltip } from "@mui/material";
import { ExclamationIcon } from "@pancakeswap/uikit";
import * as React from 'react';

export const LightTooltip = ({ title, icon, showIcon = true, children }: { title: string, icon?: React.ReactNode, showIcon?: boolean, children?: React.ReactNode }) => {
    return (
        <Tooltip placement="top" arrow title={title}
            componentsProps={{
                tooltip: {
                    sx: {
                        // fontSize: "13px",
                        padding: "8px 12px"
                        // color: "#fff",
                        // bgcolor: "#8C8D8E",
                        // "& .MuiTooltip-arrow": {
                        //     color: "#8C8D8E",
                        // },
                    },
                },
            }}
        >
            {
                showIcon ?
                    <div>
                        {
                            icon || <ExclamationIcon />
                        }
                    </div>
                    : <p>{children}</p>
            }
        </Tooltip>
    )
}