import { UseActivePool } from 'config/constants/ccqSupport/constants'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataItems } from 'state/asset/fetchData'
import { AppDispatch, AppState } from '../index'
import { fetchOrderItems, fetchStatusLoading, getBalanceAction, getListItems, getListListing, getUserBalance } from './actions'
import { fetchIntervestTermRate, fetchListingItems, fetchMarketPlaceAssest, fetchTotalItems, fetchUserBalanceOrder, getMarketPlaceBalance } from './fetchData'

export const UseCoreOrder = (contractMarketplaceAddress: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const { messageHash } = GetDataOrder();
    useEffect(() => {
        const getDataListOrder = async () => {
            try {
                dispatch(fetchStatusLoading({ isLoading: true }))
                const activePools = await UseActivePool(chainId)

                const resultFetchTotalItems = await fetchTotalItems(contractMarketplaceAddress, chainId);
                const [resultListListing, resultWrappedInfo] = await Promise.all([
                    fetchListingItems(contractMarketplaceAddress, resultFetchTotalItems?.totalItems, account, chainId),
                    fetchDataItems(activePools, account, chainId),

                ])
                const resultIntervestTermRate = await fetchIntervestTermRate(resultListListing?.listListing, chainId)
                const resultFetchUserAssest = await fetchMarketPlaceAssest(resultListListing?.listListing, contractMarketplaceAddress, chainId, account)

                const resultGetBalance = await getMarketPlaceBalance(resultListListing?.listListing, contractMarketplaceAddress, chainId)
                const resultBalancesOrder = await fetchUserBalanceOrder(
                    contractMarketplaceAddress,
                    resultGetBalance?.listResultBalance,
                    resultFetchUserAssest?.listUserAsset,
                    resultWrappedInfo?.listWrappedItem,
                    resultIntervestTermRate?.listIntervestRate,
                    resultListListing?.listListing,
                    resultFetchTotalItems?.totalItems,
                    account,
                    chainId,
                )

                dispatch(fetchOrderItems(resultWrappedInfo))
                dispatch(getListListing({ listListing: resultListListing?.listListing }))
                dispatch(getUserBalance(resultBalancesOrder))
                dispatch(getBalanceAction(resultGetBalance))
                dispatch(fetchStatusLoading({ isLoading: false }))
            } catch (e) {
                console.log(e)
            }
        }
        if (contractMarketplaceAddress?.length) {
            getDataListOrder()
        } else {
            dispatch(getListItems())
        }
    }, [chainId, contractMarketplaceAddress, dispatch, account, messageHash])
}

export const GetDataOrder = () => {
    const dataOrder = useSelector<AppState, AppState['order']>((state) => state.order)
    return dataOrder
}
