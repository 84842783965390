import { StyledButtonDownloadExcel } from "components/WrapperExportExcel";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { AssetItemsWidthBalanceHome } from 'state/manage/type';
import BigNumber from "bignumber.js";

const ExcelManage = ({ dataExcel }: { dataExcel: AssetItemsWidthBalanceHome[] }) => {
    return (
        <div>
            {dataExcel.length > 0 && <StyledButtonDownloadExcel>
                <div>
                    <p>EXPORT</p>
                    <img src="./images/excel.png" alt="" />
                </div>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="ExcelManage"
                    filename={`CCQ${moment().format("DD/MM/YYYY")}`}
                    sheet="tablexls"
                    buttonText=""
                />
            </StyledButtonDownloadExcel>}
            <div style={{ display: "none" }}>
                {
                    dataExcel.length > 0 && <table id="ExcelManage">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên CCQ</th>
                                <th>Kỳ trả lợi suất</th>
                                <th>Ngày trả lợi suất còn lại</th>
                                <th>Tổng CCQ User sở hữu</th>
                                <th>Lợi suất hiện tại</th>
                                <th>Lợi suất thực trả</th>
                                <th>Lợi nhuận kỳ trước</th>
                                <th>Đơn vị phát hành thanh toán (VND)</th>
                                <th>Tạm ứng kỳ tiếp theo (VND)</th>
                                <th>Tổng CCQ Hiện tại</th>
                                <th>Tổng niêm yết</th>
                                <th>Mệnh giá (VND)</th>
                                <th>Thông báo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...dataExcel].sort((a, b) => b.issueDate - a.issueDate).map((item: AssetItemsWidthBalanceHome, index) => {
                                const totalBalance = Number(item.totalSupply) - Number(item.totalListing) - Number(item.nftBalance)
                                const profitConvert = Number(new BigNumber(item?.profit).decimalPlaces(2, 1))
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.ccqName}</td>
                                        <td>{item.intervestTerm === 92 ? 3 : 6} tháng</td>
                                        <td>{item.formatNextInterestPaymentDate}</td>
                                        <td>{totalBalance}</td>
                                        <td>{`${Number((profitConvert)).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%` ?? 0}</td>
                                        <td>
                                            {Number((item?.yieldNextMonth)).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 0}
                                        </td>
                                        <td>
                                            {Number(item?.yieldLastMonth).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 0}
                                        </td>
                                        <td>
                                            {Number(item.isPay === false ? '0' : item.fullTermYield).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 0}
                                        </td>
                                        <td>
                                            {(Number(item.fullTermYield)).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 0}
                                        </td>
                                        <td>
                                            {Number(item.totalSupply).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 0}
                                        </td>
                                        <td>
                                            {item.totalListing ? Number(item.totalListing).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}
                                        </td>
                                        <td>
                                            {Number(item.denominations).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? '-'}
                                        </td>
                                        <td>
                                            {item.isPay ? "Đã thanh toán" : "Chưa thanh toán"}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
};
export default ExcelManage;