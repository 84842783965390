import { ccqSupport } from 'config/constants/ccqSupport/constants';
import contracts from 'config/constants/contracts';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { renderTokenVNDCByChain } from 'hooks/useRenderTokenByChain';
import { getAddress } from 'utils/addressHelpers';

import { GetDataAsset, UseCoreAsset } from 'state/asset';
import { GetDataManage, UseCoreManage } from 'state/manage';
import { GetDataOrder, UseCoreOrder } from 'state/order';
import { AssetItemsWidthBalance } from 'state/asset/type';


export interface buysType {
    nftAddress: string,
    quantity: number,
    nftId: number
}
interface OptionsType {
    buys?: buysType[],
    account?: string,
    chainId?: number,
    marketplaceContractAddress?: string,
    itemKey?: number,
}

export type HandlePropsAsset = AssetItemsWidthBalance & OptionsType;

export const useFetchData = () => {
    const { account, chainId } = useActiveWeb3React();
    const tokenVndc = renderTokenVNDCByChain(chainId);
    const contractMarketplaceAddress = getAddress(contracts?.marketplace, chainId);
    const ccqConfig = ccqSupport(chainId);

    UseCoreAsset(contractMarketplaceAddress, account, chainId);
    UseCoreManage(ccqConfig, contractMarketplaceAddress, tokenVndc?.address, account, chainId);
    UseCoreOrder(contractMarketplaceAddress, account, chainId);

    const storeDataManage = GetDataManage();
    const storeDataAsset = GetDataAsset();
    const storeDataOrder = GetDataOrder();

    const getTrustpayDetail = (nftAddress: string) => {
        return storeDataManage?.listUserIntervest?.find((it) => it?.nftAddress?.toLowerCase() === nftAddress?.toLowerCase())
    };

    const dataNftAddressAsset = storeDataAsset.listUserBalance.length && storeDataAsset.listUserBalance.reduce((acc: HandlePropsAsset[], pre: AssetItemsWidthBalance) => {
        const index = acc.findIndex((item: AssetItemsWidthBalance) => item.nftAddress.toLowerCase() === pre.nftAddress.toLowerCase());
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [{
                    nftAddress: pre.nftAddress,
                    quantity: pre.nftBalance,
                    nftId: pre.nftId
                }]
            })
        } else {
            const item = [...acc[index].buys]
            item.push({
                nftAddress: pre.nftAddress,
                quantity: pre.nftBalance,
                nftId: pre.nftId
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                nftBalance: acc[index].nftBalance + pre.nftBalance,
                buys: item
            };
        }
        return acc;
    }, []);

    const dataNftAddressOrder = storeDataOrder.listUserBalance.length && storeDataOrder.listUserBalance.reduce((acc: any[], pre: AssetItemsWidthBalance) => {
        const index = acc.findIndex((item: AssetItemsWidthBalance) => item.nftAddress.toLowerCase() === pre.nftAddress.toLowerCase());
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [{
                    nftAddress: pre.nftAddress,
                    quantity: pre.totalListing,
                    nftId: pre.idMarketPlace
                }]
            })
        } else {
            const item = [...acc[index].buys]
            item.push({
                nftAddress: pre.nftAddress,
                quantity: pre.totalListing,
                nftId: pre.idMarketPlace
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                totalListing: acc[index].totalListing + pre.totalListing,
                buys: item
            };
        }
        return acc;
    }, []);

    return {
        storeDataOrder,
        storeDataManage,
        storeDataAsset,
        getTrustpayDetail,
        contractMarketplaceAddress,
        account,
        chainId,
        dataNftAddressAsset,
        dataNftAddressOrder
    }
};
