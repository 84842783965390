import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { PAY_DAY_NEXT_PROFIT, UNITDATE } from "config";
import { Items } from "state/homeFundgo/type";
import { StyledButtonDownloadExcel } from "components/WrapperExportExcel";


const ExportExcel = ({ dataExcel }: { dataExcel: Items[] }) => {
    const numArraySort = [...dataExcel].sort((a: Items, b: Items) => b.currentYield - a.currentYield);
    return (
        <div>
            {dataExcel.length > 0 && <StyledButtonDownloadExcel>
                <div>
                    <p>EXPORT</p>
                    <img src="./images/excel.png" alt="" />
                </div>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={`Quan_ly_${moment().format("DD/MM/YYYY")}`}
                    sheet="tablexls"
                    buttonText=""
                />
            </StyledButtonDownloadExcel>}
            <div
                style={{ display: "none" }}
            >
                {
                    numArraySort.length > 0 && <table id="table-to-xls">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên CCQ</th>
                                <th>Đơn vị phân phối</th>
                                <th>Lợi suất</th>
                                <th>Kỳ hạn</th>
                                <th>Ngày trả lợi suất kế tiếp</th>
                                <th>Ngày còn lại</th>
                                <th>Tổng phát hành (CCQ)</th>
                                <th>Mệnh giá (VND)</th>
                                <th>Tương đương (VND)</th>
                                <th>Lợi suất kỳ này (VND)</th>
                                <th>Cần thanh toán (VND)</th>
                                <th>Thông báo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {numArraySort.map((item: Items, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.ccqName}</td>
                                    <td>{item.distributor}</td>
                                    <td>{`${Number(item.profit) * 100}%`}</td>
                                    <td>{`${item.intervestTerm === 92 ? 3 : 6} tháng`}</td>
                                    <td>{moment(item.paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT)}</td>
                                    <td>{item.dayDiff} {UNITDATE}</td>
                                    <td> {Number(item.totalSupply).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}</td>
                                    <td>
                                        {Number(item.denominations).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                    </td>
                                    <td> {item.totalPrice.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}</td>
                                    <td>
                                        {item.currentYield.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                    </td>
                                    <td>{item.currentYield.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}</td>
                                    <td>
                                        {item.isPay ? "Đã thanh toán" : "Chưa thanh toán"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
};


export default ExportExcel;
