
import { UseActivePool } from 'config/constants/ccqSupport/constants';
import { ccqSupportItems } from 'config/constants/ccqSupport/constants/type';
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'state';
import { fetchIntervestTermRate } from 'state/asset/fetchData';
import { useRouter } from 'next/router';
import { actionDispatchListTerm, dataChartAction, fetchListIntervest, fetchManagerItems, getStatusLoading, getUserBalance } from "./actions";
import { fetchDataChart, fetchDataUser, fetchInterVest, fetchListTerm, fetchListWrappedInfo, fetchOwner, fetchTotalListing, fetchUserAssest, fetchUserBalance } from './fetchData';

export const GetDataManage = () => {
    const result = useSelector<AppState, AppState['manage']>((state) => state.manage)
    return result
}

export const UseCoreManage = (ccqConfig: ccqSupportItems[], contractMarketplaceAddress: string, tokenVNDCAddress: string, account: string, chainId: number,) => {
    const dispatch = useDispatch<AppDispatch>()
    const router = useRouter()
    const { payItem, transactionHash } = GetDataManage();
    // eslint-disable-next-line camelcase
    const nft_Address = router.query?.nftAddress as string;

    useEffect(() => {
        const getWhitelist = async () => {
            try {
                dispatch(getStatusLoading({ isLoading: !false }))
                const activePools = await UseActivePool(chainId)
                const resultWrappedInfo = await fetchListWrappedInfo(activePools, account, chainId, nft_Address)
                const resultOwner = await fetchOwner(resultWrappedInfo.listWrappedItem , chainId)
                const resultAsset = await fetchUserAssest(resultWrappedInfo.listWrappedItem, account, chainId)

                const resultInvertRate = await fetchIntervestTermRate(activePools, chainId)
                const { totalListing } = await fetchTotalListing(contractMarketplaceAddress, account, chainId)
                const resultDataUser = await fetchDataUser(tokenVNDCAddress, resultAsset?.listUserAsset, account, chainId)
                const resultBalances = await fetchUserBalance(
                    contractMarketplaceAddress,
                    resultDataUser?.listItems,
                    resultAsset?.listUserAsset,
                    resultWrappedInfo?.listWrappedItem,
                    resultInvertRate?.listIntervestRate,
                    totalListing,
                    account,
                    chainId,
                    resultOwner
                    )

                const fetchIntervest = await fetchInterVest(resultBalances?.listUserBalance, account, chainId)
                dispatch(fetchListIntervest(fetchIntervest))
                dispatch(fetchManagerItems(resultWrappedInfo))
                dispatch(getUserBalance(resultBalances))
                dispatch(getStatusLoading({ isLoading: false }))
            } catch (e) {
                console.log(e)
            } finally {
                dispatch(getStatusLoading({ isLoading: false }))
            }
        }
        getWhitelist()
        // eslint-disable-next-line camelcase
    }, [ccqConfig, account, chainId, tokenVNDCAddress, dispatch, contractMarketplaceAddress, transactionHash, nft_Address])

    useSlowRefreshEffect(() => {
        (async () => {
            if (payItem) {
                const result = await fetchListTerm(payItem, chainId);
                const resultDataChart = await fetchDataChart(payItem);
                dispatch(actionDispatchListTerm(result));
                dispatch(dataChartAction(resultDataChart));
            }
        })();
    }, [chainId, dispatch, payItem, transactionHash]);
}