import { MaxUint256 } from '@ethersproject/constants'
import { useTranslation } from "@pancakeswap/localization"
import { useToast } from "@pancakeswap/uikit"
import { ToastDescriptionWithTx } from "components/Toast"
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice"
import { useERC20 } from "hooks/useContract"
import { renderTokenVNDCByChain } from 'hooks/useRenderTokenByChain'
import { useUserRejected } from 'hooks/useUserRejected'
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "state"
import { actionsTransactionHash } from 'state/manage/actions'

export const useApprove = (contractNftAddress: string, chainId: number) => {
    const { handleRejected } = useUserRejected();
    const tokenVndc = renderTokenVNDCByChain(chainId)
    const tokenContract = useERC20(tokenVndc.address)

    const { toastSuccess, toastError } = useToast()
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
    const { t } = useTranslation()
    const [pendingTx, setPendingTx] = useState(false)
    const dispatch = useDispatch<AppDispatch>()

    const handleApprove = useCallback(async () => {
        setPendingTx(true)
        try {
            const tx = await callWithMarketGasPrice(tokenContract, 'approve', [contractNftAddress, MaxUint256])
            const receipt = await tx.wait()
            if (receipt.status) {
                toastSuccess(
                    t('Chấp thuận thành công'),
                    <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                )

                setPendingTx(false)
                dispatch(actionsTransactionHash({ transactionHash: receipt.transactionHash }))
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Đã có lỗi xảy ra'), t('Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !'))
                setPendingTx(false)
            }
        } catch (e) {
            setPendingTx(false)
            handleRejected(e);
        }
    }, [callWithMarketGasPrice, contractNftAddress, dispatch, handleRejected, t, toastError, toastSuccess, tokenContract])

    return { handleApprove, pendingTx }
}
