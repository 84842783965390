import { Flex, Text, Skeleton } from "@pancakeswap/uikit";
import Countdown, { zeroPad } from 'react-countdown';

export function convertDate(date: any){  
    if (date) {  
        const today=  new Date(date);
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); 
        const yyyy = today.getFullYear();
        return <Flex alignItems="center" justifyContent="center">
            <>{dd}/{mm}/{yyyy}</>
        </Flex>;    
    }
    return '--'
}

export const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return (
        <Text bold>{zeroPad(seconds)} ngày</Text>
    )
    return (
        <Text bold>{zeroPad(days)} ngày</Text>
    )
}   

// export const getDateBetweenTwoDates = (endDate:number) => {
//     const start = moment()
//     const end = moment(endDate)
//     if ( end.diff(start, "days") < 0 ) return 0
//     return end.diff(start, "days")
// }    