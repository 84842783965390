import { useTranslation } from "@pancakeswap/localization";
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';
import { useRouter } from 'next/router';
import { Flex, DotsBG1Icon, DotsBG2Icon, BackgroundMobileLeft, BackgroundMobileRight } from "@pancakeswap/uikit";

const PageMeta = () => {
    const {
        t,
        currentLanguage: { locale },
    } = useTranslation()
    const { pathname } = useRouter()
    const pageMeta = getCustomMeta(pathname, t, locale) || {}
    const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
    const pageTitle = title
    return (
        <MetaTags>
            <title>{pageTitle}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
        </MetaTags>
    )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
    const { pathname } = useRouter()
    const isDisableDot = pathname.includes('fungo')
    return (
        <Wrapper>
            <PageMeta />
            <Row>
                <ColImageLeft>
                    {
                        !isDisableDot && <CsDotsBG1Icon />
                    }
                    <CsDotsBG2Icon />
                    <CsBackgroundMobileLeft />
                </ColImageLeft>
                <ColImageRight>
                    <CsDotsBG1RightIcon />
                    <CsDotsBG2RightIcon />
                    <CsBackgroundMobileRight />
                </ColImageRight>
            </Row>
            {children}
        </Wrapper>
    )
}

export default PageFullWidth

const Row = styled.div`
  height: 100%;
`

const Wrapper = styled.div`
    /* background-color: green; */
    width:100%;
    height: auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    &::before{
      content: '';
      position: absolute;
      width: 28px;
      height: 470px;
      left: -14px;
      top: 1226px;
      background: #F9A138;
      border-radius: 56px;
      @media screen and (max-width: 1280px) {
        display: none;
      }
    }
    &::after{
      content: '';
      position: absolute;
      width: 28px;
      height: 315px;
      right: -14px;
      top: 612px;
      background: #F9A138;
      border-radius: 56px;
      @media screen and (max-width: 1280px) {
        display: none;
      }
    }
`
const ColImageLeft = styled(Flex)`
    width:180px;
    height: 230px;
    position: absolute;
    top:18px;
    left: -30px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        width:150px;
        height: 250px;
        left: -35px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width:100px;
        height: 220px;
        left: -35px;
    }
    @media screen and (max-width: 600px){
      height: 35px;
      width: 35px;
      left: 0px;
      top: 0px;
    }
`
const ColImageRight = styled(Flex)`
    width:200px;
    height: 250px;
    position: absolute;
    top:38px;
    right: 0px;
    @media screen and (min-width: 1281px) and (max-width: 1400px) {
        height: 250px;
        right: -20px;
    }
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        height: 240px;
        width: 150px;
        right: -20px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        height: 240px;
        width: 100px;
        right: -20px;
    }
    @media screen and (max-width: 600px){
        height: 240px;
        top:0px;
        z-index: 1;
    }
`
const CsDotsBG1Icon = styled(DotsBG1Icon)`
    position: absolute;
    top:0px;
    right: 0px;
    height: 65px;
    width: 65px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
      height: 65px;
      width: 65px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
      height: 35px;
      width: 35px;
    }
    @media screen and (max-width: 600px){
      display: none;
    }
`
const CsDotsBG2Icon = styled(DotsBG2Icon)`
    position: absolute;
    bottom:0px;
    left: 0px;
    height: 80px;
    width: 78px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        height: 90px;
        width: 88px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
      height: 60px;
      width: 58px;
    }
    @media screen and (max-width: 600px){
      display: none;
    }
`
const CsDotsBG1RightIcon = styled(DotsBG1Icon)`
    position: absolute;
    top:0px;
    left: 0px;
    height: 65px;
    width: 65px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
      height: 65px;
      width: 65px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        height: 45px;
        width: 45px;
    }
    @media screen and (max-width: 600px){
       display: none;
    }
`
const CsDotsBG2RightIcon = styled(DotsBG2Icon)`
    position: absolute;
    bottom:0px;
    right: -20px;
    height: 70px;
    width: 68px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        height: 70px;
        width: 68px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
      height: 65px;
      width: 65px;
    }
    @media screen and (max-width: 600px){
      display: none;
    }
`
const CsBackgroundMobileLeft = styled(BackgroundMobileLeft)`
    position: absolute;
    top:0px;
    left: -5px;
    height: 49px;
    width: 49px;
    @media screen and (min-width: 600px){
      display: none;
    }
`

const CsBackgroundMobileRight = styled(BackgroundMobileRight)`
    position: absolute;
    top:0px;
    right:0px;
    height: 49px;
    width: 27px;
    height: 140px;
    @media screen and (min-width: 600px){
      display: none;
    }
`