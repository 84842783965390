import { ChainId } from "@pancakeswap/sdk"
import contracts from "config/constants/contracts"
import { bscTokens, bscTestnetTokens, onusMainnetTokens } from "@pancakeswap/tokens"
import { getAddress } from "./addressHelpers"

export function renderMarketplaceAddress(chainId) {
    return getAddress(contracts?.fcRoot, chainId)
}
export function renderToken(chainId) {
    if (chainId !== ChainId.ONUS_TESTNET) return bscTestnetTokens?.vndc
    return onusMainnetTokens.vndc
}