import useActiveWeb3React from "hooks/useActiveWeb3React";
import { GetDataListsHome, UseCoreListHome } from "state/homeFundgo";
import { renderToken } from "utils/renderToken";

export const useFetchData = () => {
    const { chainId, account } = useActiveWeb3React();
    const token = renderToken(chainId)
    UseCoreListHome(token?.address, account, chainId);
    const dataListHome = GetDataListsHome();
    return {
        dataListHome
    }
};