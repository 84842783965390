import BigNumber from "bignumber.js";
import { PAY_DAY_NEXT_PROFIT, UNITDIFF } from "config";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { ERC20_ABI } from "config/abi/erc20";
import { BASE_RATE_INTERVEST } from "config/constants";
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type";
import { axiosClient, tOpt } from "config/htttp";
import moment from "moment";
import { AssetItemsWidthBalance, IntervestRate, ListUserBalance, itemListing } from "state/asset/type";
import multicall from "utils/multicall";

import { isTrustPay } from "hooks/isTrustPay";
import { Item, ListIntervest, ListItem, ListTerm, ListUserAsset, WrappedItems, AssetItems } from "./type";

export const fetchListWrappedInfo = async (ccqConfig: ccqSupportItems[], account: string, chainId: number, nftAddress: string) => {
    try {
        if (account?.length > 0) {
            const getNumberTerm = async () => {
                try {
                    const callsNumberTerm = ccqConfig.map((item) => ({
                        address: item.ccqWrrapped?.address,
                        name: 'numberTerm',
                        params: []
                    }));
                    return await multicall(ccqWrappedAbi, callsNumberTerm, chainId)
                } catch (error) {
                    return null;
                }
            };

            const getEtfInfor = async () => {
                try {
                    const callsEtfInfor = ccqConfig.map((item) => ({
                        address: item.ccqWrrapped?.address,
                        name: 'etfInfor',
                        params: []
                    }));
                    return await multicall(ccqWrappedAbi, callsEtfInfor, chainId)
                } catch (error) {
                    return null;
                }
            };
            const getTotalProfitPublisher = async () => {
                try {
                    const callsEtfInfor = ccqConfig.map((item) => ({
                        address: item.ccqWrrapped?.address,
                        name: 'totalProfitPublisher',
                        params: []
                    }));
                    return await multicall(ccqWrappedAbi, callsEtfInfor, chainId)
                } catch (error) {
                    return null;
                }
            };

            const [resultEtfInfor, resultNumberTerm, resultTotalProfitPublisher] = await Promise.all([
                getEtfInfor(),
                getNumberTerm(),
                getTotalProfitPublisher()
            ]);

            const parseData = resultEtfInfor.map((item, index) => {
                return {
                    nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                    publisher: item?.publisher,
                    expireDate: Number(new BigNumber(item?.expireDate.toString()).toString()),
                    intervestTerm: Number(new BigNumber(item?.intervestTerm.toString()).toString()),
                    issueDate: Number(new BigNumber(item?.issueDate.toString()).toString()),
                    ccqName: item?.name,
                    denominations: Number(new BigNumber(item?.price.toString()).dividedBy(1E18).toString()),
                    totalSupply: Number(new BigNumber(item?.totalSupply.toString()).toString()),
                    numberVest: Number(new BigNumber(resultNumberTerm[index]).toString()),
                    totalProfit: Number(new BigNumber(resultTotalProfitPublisher[index].toString()).dividedBy(1E18).toString()),
                }
            });
            const filterAccount = nftAddress ?
                parseData.filter((item) =>
                    item.publisher.toLowerCase() === isTrustPay(account).toLowerCase() &&
                    item.nftAddress.toLowerCase() === nftAddress.toLowerCase()
                )
                : parseData.filter((item) =>
                    item.publisher.toLowerCase() === isTrustPay(account).toLowerCase()
                );
            return {
                listWrappedItem: filterAccount
            }
        }
        return {
            listWrappedItem: []
        }
    }
    catch (error) {
        console.log(error)
        return {
            listWrappedItem: []
        }
    }
}

export const fetchOwner = async (listWrappedItem: WrappedItems[], chainId: number) => {
    try {
        if (listWrappedItem?.length > 0) {
            const calls = [];
            for (let index = 0; index < listWrappedItem?.length; index++) {
                calls.push({
                    address: listWrappedItem[index]?.nftAddress,
                    name: 'owner',
                    params: []
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            return result
        }
        return null
    } catch (error) {
        return 0
    }
};

export const fetchUserAssest = async (listWrappedItem, account: string, chainId: number): Promise<ListUserAsset> => {
    try {
        if (account?.length > 0) {
            const getMyAsset = [];
            const callGetMyAsset = listWrappedItem.map((item) => ({
                address: item.nftAddress,
                name: 'getMyAsset',
                params: [isTrustPay(account)]
            }));
            const resultGetMyAsset = await multicall(ccqWrappedAbi, callGetMyAsset, chainId);
            // push array of all contracts nftAddress getMyAsset
            for (let index = 0; index < resultGetMyAsset?.length; index++) {
                for (let subIndex = 0; subIndex < resultGetMyAsset[index][0]?.length; subIndex++) {
                    getMyAsset.push(
                        {
                            nftAddress: listWrappedItem[index]?.nftAddress,
                            nftId: Number(new BigNumber(resultGetMyAsset[index][0][subIndex]?.toString()).toString())
                        }
                    )
                }
            }
            // call read Contract getBalanceOf
            const callGetBalance = getMyAsset.map((item) => ({
                address: item.nftAddress,
                name: 'balanceOf',
                params: [isTrustPay(account), Number(item?.nftId)]
            }));
            const resultGetBalance = await multicall(ccqWrappedAbi, callGetBalance, chainId);
            const parseDataBalance = resultGetBalance && resultGetBalance?.map((item, index) => {
                return {
                    balanceOf: new BigNumber(item).toString(),
                    nftAddress: getMyAsset[index]?.nftAddress,
                    nftId: getMyAsset[index]?.nftId,
                }
            })
            // call read Contract getMyVestList
            const callGetMyVestList = getMyAsset && getMyAsset.map((item) => ({
                address: item.nftAddress,
                name: 'getMyVestList',
                params: [isTrustPay(account), Number(item?.nftId)]
            }));
            const resultMyVestList = await multicall(ccqWrappedAbi, callGetMyVestList, chainId);

            // parse data to bignumber
            const parseDataMyVestList = resultMyVestList && resultMyVestList?.map((item, index) => {
                const nftAddress = getMyAsset[index]?.nftAddress;
                const mapVestList = item && item[0].map((l, k) => {
                    return {
                        amount: Number(new BigNumber(l?.amount?.toString()).dividedBy(1E18).toString()),
                        isVested: l?.isVested,
                        nextInterestPaymentDate: Number(new BigNumber(l?.vestDate?.toString()).toString()),
                        intervestPayed: Number(new BigNumber(l?.intervestPayed?.toString()).toString()),
                        index: k + 1,
                    }
                });
                return {
                    myVestList: mapVestList,
                    nftAddress,
                }
            });

            // filter array nftAddress getMyVestList result []
            const filteredArray = listWrappedItem && listWrappedItem.filter((item) => {
                return parseDataMyVestList && parseDataMyVestList.every((element) => element?.nftAddress !== item?.nftAddress);
            });

            const dataVestingMap = filteredArray && filteredArray.map((item) => {

                const myVestList = [];
                for (let vestingMap = 0; vestingMap < item?.numberVest; vestingMap++) {
                    const formatStartDate = moment(moment(item?.issueDate * 1000).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY HH:mm:ss")
                        .add(UNITDIFF, (item?.intervestTerm * (vestingMap + 1)))
                    const valueOf = moment(formatStartDate).valueOf() / 1000;
                    myVestList?.push(
                        {
                            index: vestingMap + 1,
                            nextInterestPaymentDate: valueOf,
                            isVested: false,
                            intervestPayed: false,
                            amount: 0
                        }
                    )
                }
                return {
                    nftAddress: item?.nftAddress,
                    myVestList
                }
            });

            const concatArray = (parseDataMyVestList && dataVestingMap) && [...parseDataMyVestList, ...dataVestingMap];
            // filter getMyVestList with same nftAddress sum intervestPayed
            const newVestingMap = concatArray.reduce((acc, item) => {
                const index = acc.findIndex(obj => obj.nftAddress === item.nftAddress);
                if (index === -1) {
                    acc.push(item);
                }
                else {
                    acc[index]?.myVestList?.forEach((map, mapIndex) => {
                        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
                        map.intervestPayed += item?.myVestList?.[mapIndex]?.intervestPayed;
                    });
                }
                return acc;
            }, []);
            // filter array with the same nftAddress
            const filterNftAddress = listWrappedItem.map((item) => {
                const listVesting = newVestingMap?.find(vm => vm.nftAddress.toLowerCase() === item.nftAddress.toLowerCase());
                const filterBalance = parseDataBalance?.filter(bl => bl.nftAddress.toLowerCase() === item.nftAddress.toLowerCase());
                const sumBalance = filterBalance?.reduce((curr, prev) => {
                    return curr + Number(prev?.balanceOf)
                }, 0)
                return {
                    ...item,
                    listVesting: listVesting?.myVestList,
                    balanceOf: sumBalance
                }
            });
            return {
                listUserAsset: filterNftAddress
            }
        }
        return {
            listUserAsset: []
        }
    }
    catch (error) {
        return {
            listUserAsset: []
        }
    }
}

export const fetchDataUser = async (tokenAddress: string, listToken: AssetItems[], account: string | null, chainId: number): Promise<ListItem> => {
    try {
        const getBalanceToken = async () => {
            if (account?.length || account !== null) {
                try {
                    const calls = [];
                    for (let index = 0; index < listToken?.length; index++) {
                        calls.push({
                            address: tokenAddress,
                            name: 'balanceOf',
                            params: [isTrustPay(account)]
                        })
                    }
                    return await multicall(ERC20_ABI, calls, chainId)
                } catch (e) {
                    console.log(e)
                }
            }
            return null
        }
        const getAllowance = async () => {
            if (account?.length || account !== null) {
                try {
                    const calls = [];
                    for (let index = 0; index < listToken?.length; index++) {
                        calls.push({
                            address: tokenAddress,
                            name: 'allowance',
                            params: [isTrustPay(account), listToken[index]?.nftAddress]
                        })
                    }
                    return await multicall(ERC20_ABI, calls, chainId)
                } catch (e) {
                    console.log(e)
                }
            }
            return null
        }
        const [resultBalance, resultAllowance] = await Promise.all([
            getBalanceToken(),
            getAllowance()
        ])
        const resultFetchDataUser = listToken.map((item, index) => {
            return {
                ...item,
                balanceOf: new BigNumber(resultBalance[index]?.balance.toString()).toString(),
                allowance: new BigNumber(resultAllowance[index][0]?.toString()).dividedBy(1E18).toString(),
            }
        })
        return {
            listItems: resultFetchDataUser
        }
    }
    catch (error) {
        console.log(error)
        return {
            listItems: []
        }
    }
}

export const fetchTotalListing = async (contractAddress: string, account: string, chainId: number): Promise<{ totalListing: itemListing[] }> => {
    if (contractAddress?.length) {
        try {
            const calls = [];
            const listItem = [];
            const results = await multicall(marketplaceAbi, [{
                address: contractAddress,
                name: 'getTotalItems',
                params: []
            }], chainId);

            const convertResult = Number(new BigNumber(results?.toString()).toString())
            for (let index = 0; index < convertResult; index++) {
                calls.push({
                    address: contractAddress,
                    name: 'items',
                    params: [index]
                })
            }
            const resultItems = await multicall(marketplaceAbi, calls, chainId)
            for (let indexListItem = 0; indexListItem < resultItems?.length; indexListItem++) {
                listItem.push({
                    indexListItem,
                    id: Number(new BigNumber(resultItems[indexListItem]?.id?.toString()).toString()),
                    nftId: new BigNumber(resultItems[indexListItem]?.nftId?.toString()).toString(),
                    nftAddress: resultItems[indexListItem]?.nftAddress,
                    amount: new BigNumber(resultItems[indexListItem]?.amount?.toString()).toString(),
                    seller: resultItems[indexListItem]?.seller,
                    nftContract: resultItems[indexListItem]?.nftContract,
                })
            }
            const filterById = listItem?.filter((item: any) => item?.seller?.toLowerCase() === account?.toLowerCase());
            return {
                totalListing: filterById
            }
        }
        catch (_) {
            return {
                totalListing: []
            }
        }
    }
    return {
        totalListing: []
    }
}

export const fetchUserBalance = async (
    contractMarketplaceAddress: string,
    listDateUser: Item[],
    listUserAsset: AssetItems[],
    listWrappedItem: WrappedItems[],
    listIntervestRate: IntervestRate[],
    totalListing: itemListing[],
    account: string,
    chainId: number,
    owner: string,
): Promise<ListUserBalance> => {
    const currTime = Date.now()
    const currentTime = Math.floor(Date.now() / 1000);

    try {
        const getOnceTermIntervest = async () => {
            if (listUserAsset?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'onceTermIntervest',
                        params: []
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getApproveForAll = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'isApprovedForAll',
                        params: [isTrustPay(account), contractMarketplaceAddress]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getCurrentPrice = async () => {
            if (account?.length) {
                try {
                    const calls = [];
                    for (let index = 0; index < listUserAsset?.length; index++) {
                        calls.push({
                            address: listUserAsset[index]?.nftAddress,
                            name: 'getPriceAtTime',
                            params: [currentTime]
                        })
                    }
                    return await multicall(ccqWrappedAbi, calls, chainId)
                } catch (e) {
                    console.log(e)
                    return null
                }
            }
            return null
        }
        const getNumberTerm = async () => {
            if (account?.length) {
                try {
                    const calls = [];
                    for (let index = 0; index < listUserAsset?.length; index++) {
                        calls.push({
                            address: listUserAsset[index]?.nftAddress,
                            name: 'numberTerm',
                            params: []
                        })
                    }
                    return await multicall(ccqWrappedAbi, calls, chainId)
                } catch (e) {
                    console.log(e)
                    return null
                }
            }
            return null
        }
        const getTotalAdvanceIntervest = async () => {
            if (account?.length) {
                try {
                    const calls = [];
                    for (let index = 0; index < listUserAsset?.length; index++) {
                        calls.push({
                            address: listUserAsset[index]?.nftAddress,
                            name: 'totalAdvanceIntervest',
                            params: []
                        })
                    }
                    return await multicall(ccqWrappedAbi, calls, chainId)
                } catch (e) {
                    console.log(e)
                    return null
                }
            }
            return null
        }
        const getntervestTermRate = async () => {
            if (listUserAsset?.length > 0) {
                const callsIntervest = []
                for (let index = 0; index < listUserAsset?.length; index++) {
                    callsIntervest.push(
                        {
                            address: listUserAsset[index]?.nftAddress,
                            name: 'intervestTermRate',
                            params: []
                        }
                    )
                }
                const resultIntervest = await multicall(ccqWrappedAbi, callsIntervest, chainId);
                const result = []
                if (resultIntervest?.length) {
                    for (let index = 0; index < resultIntervest?.length; index++) {
                        result.push({
                            profit: new BigNumber(resultIntervest[index].toString()).dividedBy(BASE_RATE_INTERVEST).multipliedBy(10).toString(),
                            nftAddress: listUserAsset[index]?.nftAddress,
                        })
                    }
                }
                return result
            }
            return null
        }
        const getIsNotExpired = async () => {
            if (listUserAsset?.length > 0) {
                try {
                    const callsIsNotExpired = []
                    for (let index = 0; index < listUserAsset?.length; index++) {
                        callsIsNotExpired.push(
                            {
                                address: listUserAsset[index]?.nftAddress,
                                name: 'isNotExpired',
                                params: []
                            }
                        )
                    }
                    return await multicall(ccqWrappedAbi, callsIsNotExpired, chainId);
                } catch (e) {
                    console.log(e)
                    return null
                }

            }
            return null
        }
        const [
            resultGetOnceTermIntervest,
            resultGetApproveForAll,
            resultGetCurrentPrice,
            resultNumberTerm,
            resultTotalAdvanceIntervest,
            resultGetntervestTermRate,
            resultGetIsNotExpired,
        ] = await Promise.all([
            getOnceTermIntervest(),
            getApproveForAll(),
            getCurrentPrice(),
            getNumberTerm(),
            getTotalAdvanceIntervest(),
            getntervestTermRate(),
            getIsNotExpired(),
        ])
        const data = [];

        for (let index = 0; index < listWrappedItem?.length; index++) {
            const findTotalListing = totalListing?.filter((item) => item?.nftAddress?.toLowerCase() === listWrappedItem[index]?.nftAddress?.toLowerCase())
            const resultFindTotalListing = findTotalListing?.reduce((curr, prev) => {
                return curr + Number(prev?.amount)
            }, 0)

            const holdingTime = moment(Number(Date.now())).diff(moment(Number(listUserAsset[index]?.issueDate)), UNITDIFF)
            const numberVest = Number(new BigNumber(resultNumberTerm[index][0].toString()).toString())

            const lengthVestingMap = listUserAsset[index]?.listVesting?.length - 1;
            const nextInterestPaymentDateLastMonth = listUserAsset[index]?.listVesting[lengthVestingMap].nextInterestPaymentDate;
            const nextVestingMap = listUserAsset[index]?.listVesting?.find((d) => currTime < d?.nextInterestPaymentDate * 1000);

            const checkDayDiff = nextVestingMap ? moment(moment(nextVestingMap?.nextInterestPaymentDate * 1000)).diff(moment(Number(Date.now())), UNITDIFF) : -1;
            const convertCheckDayDiff = checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1
            const paydayNextProfit = nextVestingMap ? (nextVestingMap?.nextInterestPaymentDate) : nextInterestPaymentDateLastMonth || 0;
            const resultDataVestingMap = listUserAsset[index]?.listVesting?.reduce((curr, prev) => {
                return prev?.intervestPayed && (curr + (Number(prev?.intervestPayed / 1E18)))
            }, 0)
            const toaltBalanceUserOwn = Number(listUserAsset[index]?.totalSupply) - Number(listUserAsset[index]?.balanceOf) - Number(resultFindTotalListing)
            data.push(
                {
                    tokenId: index,
                    index,
                    balanceOf: listDateUser[index]?.balanceOf,
                    allowance: listDateUser[index]?.allowance,
                    balanceUserOwn: toaltBalanceUserOwn < 0 ? 0 : toaltBalanceUserOwn,
                    nftBalance: listUserAsset[index]?.balanceOf,
                    expireDate: listWrappedItem[index]?.expireDate,
                    intervestTerm: listWrappedItem[index]?.intervestTerm,
                    totalProfit: listWrappedItem[index]?.totalProfit,
                    issueDate: listWrappedItem[index]?.issueDate,
                    ccqName: listWrappedItem[index]?.ccqName,
                    denominations: new BigNumber(listWrappedItem[index]?.denominations?.toString()).toString(),
                    publisher: listWrappedItem[index]?.publisher,
                    totalSupply: listWrappedItem[index]?.totalSupply,
                    nftAddress: listWrappedItem[index]?.nftAddress,
                    nftId: listUserAsset[index]?.nftId,
                    isExpire: currTime > listWrappedItem[index]?.expireDate * 1000,
                    profit: resultGetntervestTermRate[index]?.profit,
                    fullTermYield: new BigNumber(resultGetOnceTermIntervest[index].toString()).dividedBy(1e18).toString(),
                    totalListing: Number(resultFindTotalListing) || 0,
                    unitPrice: new BigNumber(resultGetCurrentPrice[index]?.toString()).dividedBy(1E18).toString(),
                    isApprovedForAll: resultGetApproveForAll[index][0],
                    paydayNextProfit,
                    dayDiff: convertCheckDayDiff <= 0 ? 0 : convertCheckDayDiff,
                    totalAdvanceIntervest: Number(new BigNumber(resultTotalAdvanceIntervest[index]?.toString()).dividedBy(1E18).toString()),
                    listVesting: listUserAsset[index]?.listVesting,
                    numberVest,
                    holdingTime,
                    formatNextInterestPaymentDate: paydayNextProfit ? moment(paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT) : "-",
                    realInterestPaid: resultDataVestingMap || 0,
                    owner: owner[index][0],
                    isNotExpire: resultGetIsNotExpired[index][0]
                }
            )
        }
        const callsIsIntervestPayed = data.map((item) => ({
            address: item.nftAddress,
            name: 'isIntervestPayed', // check da duoc thanh toan chua
            params: [item?.owner, item?.paydayNextProfit]
        }));

        const isAdvanceIntervestPayed = data.map((item) => ({
            address: item.nftAddress,
            name: 'isAdvanceIntervestPayed',
            params: [isTrustPay(account), item?.paydayNextProfit]
        }))

        const resultIntervestPayed = await multicall(ccqWrappedAbi, callsIsIntervestPayed, chainId);
        const resultIsAdvanceIntervestPayed = await multicall(ccqWrappedAbi, isAdvanceIntervestPayed, chainId);
        return {
            listUserBalance: data.map((item, index) => ({
                ...item, isPay: resultIntervestPayed[index][0],
                index,
                isPrepay: resultIsAdvanceIntervestPayed[index][0],
            }))
        }
    } catch (error) {
        return {
            listUserBalance: []
        }
    }
}

export const fetchDataChart = async (payItem: any) => {
    try {
        const resultTotp = tOpt()
        if (payItem?.nftAddress && resultTotp) {
            const response = await axiosClient.get(`/statistic/${payItem?.nftAddress}`,
                {
                    headers:
                    {
                        "Content-Type": "application/json",
                        'Accept': '*',
                        'Connection': 'Keep-Alive',
                        'code': `${resultTotp}`,
                    },
                });
            const dataTemp = response?.data?.data
            return dataTemp
        }
        return []
    } catch (error) {
        return [];
    }
}

export const fetchInterVest = async (listUserBalance: AssetItemsWidthBalance[], account: string, chainId: number): Promise<ListIntervest> => {
    if (account?.length) {
        try {
            const data = [];
            const filterAccount = listUserBalance?.filter(item => item?.publisher?.toLowerCase() === isTrustPay(account)?.toLowerCase());
            for (let index = 0; index < filterAccount?.length; index++) {
                // eslint-disable-next-line no-await-in-loop
                const result = await fetchDataGetIntervest(filterAccount[index], isTrustPay(account), chainId);
                data?.push({
                    ...result,
                })
            }

            return {
                listUserIntervest: data
            }
        } catch (_) {
            return {
                listUserIntervest: []
            }
        }
    }
    return {
        listUserIntervest: []
    }
}

const indexLastMonthIntervest = (present: number) => {
    if (present === 0)
        return present;
    return present - 1;
}

const indexNextMonthIntervest = (present: number, lengthVesting: number) => {
    if (present === lengthVesting)
        return present - 1;
    if (present === 0)
        return present + 1;
    return present
}
const fetchDataGetIntervest = async (data, account, chainId) => {
    try {
        const { listVesting, paydayNextProfit, owner } = data;
        const checkPresentIntervest = listVesting?.findIndex(v => v?.nextInterestPaymentDate === paydayNextProfit);
        const IndexPresentIntervest = checkPresentIntervest !== -1 ? checkPresentIntervest : listVesting?.length;
        const indexLastMonth = indexLastMonthIntervest(IndexPresentIntervest);
        const indexNextMonth = indexNextMonthIntervest(IndexPresentIntervest, listVesting.length);

        const callListVesting = listVesting.map((_, index) => ({
            address: data?.nftAddress,
            name: 'getIntervest',
            params: [data?.publisher, index]
        }));
        const callIsIntervestPaid = listVesting.map((item) => ({
            address: data?.nftAddress,
            name: 'isIntervestPayed',
            params: [owner, item.nextInterestPaymentDate]
        }));

        const isAdvanceIntervestPayed = listVesting.map((item) => ({
            address: data.nftAddress,
            name: 'isAdvanceIntervestPayed',
            params: [account, item.nextInterestPaymentDate]
        }))
        const getActualUserProfit = listVesting.map((_, index) => ({
            address: data?.nftAddress,
            name: 'actualUserProfit',
            params: [index]
        }))

        const response = await multicall(ccqWrappedAbi, callListVesting, chainId);
        const resultIntervestPaid = await multicall(ccqWrappedAbi, callIsIntervestPaid, chainId);
        const resultIsAdvanceIntervestPayed = await multicall(ccqWrappedAbi, isAdvanceIntervestPayed, chainId);
        const resultGetActualUserProfit = await multicall(ccqWrappedAbi, getActualUserProfit, chainId);

        const payload = listVesting.map((item, index) => {
            return {
                ...item,
                profit: Number(new BigNumber(response[index]).dividedBy(1E18).toString()),
                isPayProfit: resultIntervestPaid[index][0],
                isPrepay: resultIsAdvanceIntervestPayed[index][0],
                actualUserProfit: Number(new BigNumber(resultGetActualUserProfit[index])?.dividedBy(1E18)?.toString()),
            }
        });
        return {
            ...data,
            presentInterVest: IndexPresentIntervest + 1,
            yieldLastMonth: payload[indexLastMonth].profit,
            yieldNextMonth: payload[indexNextMonth].profit,
            listVesting: payload,
            intervest: payload[indexLastMonth].profit,
            isPayProfitFinal: payload[indexLastMonth].isPayProfit[0],
        }
    } catch (_) {
        return [];
    }
}



export const fetchListTerm = async (payItem: AssetItemsWidthBalance, chainId: number): Promise<ListTerm[]> => {
    try {
        const account = payItem.publisher;
        const dayNow = moment(Number(Date.now()));
        const listTerm = payItem?.listVesting.map((item, term) => {
            const period = term + 1;
            const totalProfitNPH = Number(payItem?.totalSupply) * Number((payItem?.profit) / BASE_RATE_INTERVEST) * Number((payItem?.intervestTerm)) * Number(payItem?.denominations) / 365
            const totalProfitMakePaymentsUsers = Number(payItem?.totalBalance)
            const finalProfit = totalProfitNPH && Number(totalProfitNPH) - totalProfitMakePaymentsUsers
            const checkDayDiff = moment(moment(Number(item?.nextInterestPaymentDate) * 1000)).diff(dayNow, UNITDIFF)
            return {
                dayDiff: checkDayDiff <= 0 ? 0 : checkDayDiff,
                termNumber: period,
                paydayNextProfit: item?.nextInterestPaymentDate,
                intervestPayed: Number(item.intervestPayed), //  check lai
                currentYield: payItem.fullTermYield,
                allowance: payItem.allowance,
                balance: payItem.balanceOf,
                finalProfit,
            }
        });
        const isAdvanceIntervestPayed = async () => {
            const call = listTerm?.map((item) => ({
                address: payItem.nftAddress,
                name: 'isAdvanceIntervestPayed', // tam ung chua
                params: [account, item?.paydayNextProfit]
            }));
            const term = await multicall(ccqWrappedAbi, call, chainId);
            return term;
        }
        const isIntervestPayed = async () => {
            const call = listTerm?.map((item) => ({
                address: payItem?.nftAddress,
                name: 'isIntervestPayed', // check da duoc thanh toan chua
                params: [payItem?.owner, item?.paydayNextProfit]
            }));
            const term = await multicall(ccqWrappedAbi, call, chainId);
            return term;
        }
        const getIntervest = async () => {
            const call = listTerm.map((_, index) => ({
                address: payItem?.nftAddress,
                name: 'getIntervest', // xem loi nhuan
                params: [payItem?.publisher, index]
            }));
            const term = await multicall(ccqWrappedAbi, call, chainId);
            return term;
        }
        const [resultAdvanceIntervestPayed, resultIntervestPayed, resultGetIntervest] = await Promise.all([
            isAdvanceIntervestPayed(),
            isIntervestPayed(),
            getIntervest()
        ]);
        return listTerm?.map((item, index) => ({
            indexTerm: index,
            ...item,
            isPrepay: resultAdvanceIntervestPayed[index][0],
            isPay: resultIntervestPayed[index][0],
            profit: Number(new BigNumber(resultGetIntervest[index][0].toString()).toString())
        }));
    } catch (error) {
        return [];
    }
}