
import { UseActiveCCQ, UseActivePool } from 'config/constants/ccqSupport/constants';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'state';
import { fetchAssetItems, getLoading, getUserBalance } from "./actions";
import { fetchDataItems, fetchIntervestTermRate, fetchTotalListing, fetchUserAssest, fetchUserBalance, getBalance } from "./fetchData";

export const GetDataAsset = () => {
    const dataAsset = useSelector<AppState, AppState['asset']>((state) => state.asset)
    return dataAsset
}

export const UseCoreAsset = (contractMarketplaceAddress: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        const getDataAsset = async () => {
            try {
                dispatch(getLoading({ isLoading: !false }))
                const activePools = await UseActivePool(chainId)
                
                const resultWrappedInfo = await fetchDataItems(activePools, account, chainId)
                const resultAsset = await fetchUserAssest(activePools, account, chainId)
                const resultInvertRate = await fetchIntervestTermRate(activePools, chainId)
                const { totalListing } = await fetchTotalListing(resultAsset?.listUserAsset, contractMarketplaceAddress, account, chainId)
                const resultGetBalance = await getBalance(resultAsset?.listUserAsset, account, chainId)
                
                const resultBalances = await fetchUserBalance(
                    contractMarketplaceAddress,
                    resultAsset?.listUserAsset,
                    resultGetBalance?.listResultBalance,
                    resultWrappedInfo?.listWrappedItem,
                    resultInvertRate?.listIntervestRate,
                    totalListing,
                    account,
                    chainId
                )
                dispatch(fetchAssetItems(resultWrappedInfo))
                dispatch(getUserBalance(resultBalances))
                dispatch(getLoading({ isLoading: false }))
                    
            } catch (e) {
                console.log(e)
            }
        }
        getDataAsset()
    }, [account, chainId, contractMarketplaceAddress, dispatch])
}
