import styled from "styled-components";

export const WrapperExportExcel = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    ul{
        position: relative;
        left: 75px;
        @media screen and (max-width: 768px) {
            left: 0;
        }
    }
`;

export const StyledButtonDownloadExcel = styled.div`
position: relative;
background: #F9A948;
border-radius: 4px;
justify-content: center;
min-width: 130px;
@media screen and (max-width: 768px) {
        min-width: 40px;
}
div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 36px;
        p{
            font-size: 14px;
            padding: 0px;
            color: #fff;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
}
.download-table-xls-button {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    background: none;
}
`;
