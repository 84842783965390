import { useEffect, useState } from "react"
import { ccqSupportItems } from "./type"

let logged = false

export const getPoolProposalConfig = async (chainId: number) => {
    try {
        return (await import(`/${chainId}.ts`)).default.filter(
            (f: ccqSupportItems) => f.id !== null,
        ) as ccqSupportItems[]
    } catch (error) {
        if (!logged) {
            console.error('Cannot get proposals config', error, chainId)
            logged = true
        }
        return []
    }
}

export const UseActiveCCQ = async (chain: number) => {
    const ccq = await getPoolProposalConfig(chain)
    return ccq
}
export const UseActivePool = async (chain: number) => {
    const ccq = await getPoolProposalConfig(chain)
    return ccq
}
export const ccqSupport = (chainId: number) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ccqConfig, setccqConfig] = useState<ccqSupportItems[]>([])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
        () => {
            const getccqConfig = async () => {
                const activePools = await UseActivePool(chainId)
                setccqConfig(activePools)
            }
            getccqConfig()
        },
        [chainId],
    )
    return ccqConfig
}
