import React from 'react';
import styled from 'styled-components';

import { AutoRenewIcon, Flex, Text } from '@pancakeswap/uikit';
import { MENU_ENTRY_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config';
import { CustomPaginate } from 'components/CustomPaginate';
import Container from 'components/Layout/Container';
import PageFullWidth from 'components/Layout/PageFullWidth';
import SubHeader from 'components/Layout/SubHeader';
import SubNav from 'components/Layout/SubNav';
import { WrapperExportExcel } from 'components/WrapperExportExcel';
import { useFetchData } from '../useFetchData';

import { HeaderTable, Row, ExcelManage } from "./components";

const pageSize = 7;
const Home = () => {
    const { storeDataManage } = useFetchData()
    const [itemOffset, setItemOffset] = React.useState(0);

    const { listUserIntervest, isLoading } = storeDataManage;
    const sortIssueDate = [...listUserIntervest].sort((a, b) => b.issueDate - a.issueDate);
    const total = sortIssueDate.length;

    return (
        <CsPageFullWidth>
            <CsContainer width="100%" height="auto">
                <SubHeader />
                <SubNav />
                <CsFlexTable width="100%" flexDirection='column' paddingBottom="1.5rem">
                    <HeaderTable total={total} />
                    <ContainerRow>
                        {
                            isLoading ?
                                <Flex justifyContent='center' width="100%" mt="4rem" style={{ gap: '4px' }}>
                                    <Text>Đang tải dữ liệu
                                    </Text>
                                    <AutoRenewIcon spin color='text' />
                                </Flex>
                                :
                                sortIssueDate.length ?
                                    <>
                                        {
                                            sortIssueDate.splice(itemOffset, pageSize)?.map((item) =>
                                                <Row key={`${item.index}`} {...item} />
                                            )
                                        }
                                        <WrapperExportExcel>
                                            <CustomPaginate total={total} pageSize={pageSize} setItemOffset={setItemOffset} />
                                            <ExcelManage dataExcel={listUserIntervest} />
                                        </WrapperExportExcel>
                                    </> :
                                    <>
                                        <Flex justifyContent='center' width="100%" mt="4rem">
                                            <Text>Không có dữ liệu</Text>
                                        </Flex>
                                    </>
                        }
                    </ContainerRow>
                </CsFlexTable>
            </CsContainer>
        </CsPageFullWidth>
    )
};

const CsPageFullWidth = styled(PageFullWidth)`
`
const CsFlexTable = styled(Flex)`
    border-radius: 12px;
    margin-top: 1.5rem;
    @media screen and (max-width: 768px){
        margin-top: 0.5rem;
        padding: 16px 0;
        background: ${({ theme }) => theme.colors.background};
    }
`
const CsContainer = styled(Container)`
    margin-bottom: ${MENU_ENTRY_HEIGHT}px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        padding-left:6px !important;
        padding-right:6px !important;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        padding-left:20px !important;
        padding-right:20px !important;
    }

`

const ContainerRow = styled(Flex)`
    gap:12px;
    width:100%;
    flex-direction:column;
    @media screen and (max-width: 1000px){
        gap:22px;
    }
`
export default Home