import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Button, Flex, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import ContainerContract from "components/ContainerContract";
import { LightTooltip } from "components/ToolTip";
import { UnitPrice } from "components/UnitPrice";
import { PAY_DAY_NEXT_PROFIT, UNITDATE } from "config";
import { UseFundgo } from "hooks/UseFundgo";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import moment from "moment";
import React from "react";
import { GetDataListsHome } from "state/homeFundgo";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { ExchangeToken } from "components/ExchangeToken";
import {
    CsFlexLeft,
    CsFlexRight,
    CsModal,
    CsTextModalLabel,
    CsTextModalValuePrice,
    CustomLink,
    WrapItemMobile
} from "./style";

interface options {
    windowSize: number;
    title: string;
    onDismiss?: () => void;
    options?: string;
    currentYield?: number;
    allowance?: number;
    paydayNextProfit: string;
}

const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px"
};

const PayModal = (props): React.ReactElement<options> => {
    const { userBalanceOf, payItem } = GetDataListsHome();

    const { handleApprove, handleConfirm, pendingTx } = UseFundgo(payItem?.nftAddress);
    const { t } = useTranslation();
    const { chainId } = useActiveWeb3React();
    const currentTime = Math.floor(Date.now());
    const isNotAllowance = new BigNumber(payItem?.allowanceVndc).isLessThan(payItem?.currentYield)
    const isNotBalance = new BigNumber(payItem?.balanceVndc).isLessThan(payItem?.currentYield)
    function handleOpenLink(add) {
        window.open(getBlockExploreLink(add, "address", chainId));
    }

    function sAccount(dataAddress) {
        if (dataAddress?.length > 0) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }

    return (
        <CsModal onDismiss={props.onDismiss} title={props.title} minWidth="1200px">
            <Flex width="100%" flexDirection="column" alignItems="center">
                <TextTitle>{t("Thông tin chứng chỉ quỹ")}</TextTitle>
            </Flex>
            <Box>
                {props.windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <Text width="12%" style={styles}>
                                Tên
                                <LightTooltip title="Tên Chứng chỉ Quỹ" />
                            </Text>
                            <Text width="12%" style={styles}>
                                Đơn vị
                                <LightTooltip title="Đơn vị phân phối" />
                            </Text>
                            <Text width="12%" style={styles}>
                                Kỳ trả LS
                                <LightTooltip title="Kỳ trả lợi suất" />
                            </Text>
                            <Text width="12%" style={styles}>
                                Ngày trả LS
                                <LightTooltip title=" Ngày trả lợi suất kế tiếp" />
                            </Text>
                            <Text width="12%" style={styles}>
                                Ngày còn lại
                            </Text>
                            <Text style={{ ...styles, width: "14%" }}>
                                Tổng PH
                                <LightTooltip title="Tổng phát hành (CCQ)" />
                            </Text>
                            <Text style={{ ...styles, width: "12%" }}>
                                Mệnh giá
                                <LightTooltip title="Mệnh giá (VND)" />
                            </Text>
                            <Text style={{ ...styles, width: "13%" }}>
                                Tương đương
                                <LightTooltip title="Tương đương (VND)" />
                            </Text>
                            <Text style={{ ...styles, width: "12%" }}>
                                LS kỳ này
                                <LightTooltip title="Lợi suất kỳ này (VND)" />
                            </Text>
                            <Text style={{ ...styles, width: "12%" }}>
                                Thanh toán
                                <LightTooltip title="Cần thanh toán (VND)" />
                            </Text>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="12%">{payItem?.ccqName}</Text>
                                <Text width="12%">{payItem.distributor}</Text>
                                <Text width="12%">{`${payItem.intervestTerm === 92 ? 3 : 6} tháng `}</Text>
                                <Text width="12%">{moment(props.paydayNextProfit * 1000 || payItem.paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT)}</Text>
                                <Text width="12%">{payItem.dayDiff} {UNITDATE}</Text>
                                <Text width="12%">{handleFormat(payItem.totalSupply)}</Text>
                                <Text width="12%">{handleFormat(payItem.denominations)}</Text>
                                <Text width="12%">{handleFormat(payItem.totalPrice)}</Text>
                                <Text width="12%">{handleFormat(payItem.currentYield)}</Text>
                                <Text width="12%">{handleFormat(payItem.currentYield)}</Text>
                            </WrapperCard>
                        </Container>
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <CsBoxInfo>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Tên
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {payItem.ccqName}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Đơn vị phân phối
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {payItem.distributor}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Kỳ trả lợi suất
                                    </Text>
                                    <Text fontSize="14px" color="text">{`${payItem.intervestTerm} ${UNITDATE}`}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Ngày trả lợi suất kế tiếp
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {moment(props.paydayNextProfit * 1000 || payItem.paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Ngày còn lại
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {payItem.dayDiff} {UNITDATE}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Tổng phát hành (CCQ)
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {handleFormat(payItem.totalSupply)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Mệnh giá
                                    </Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "5px" }}>
                                        {handleFormat(payItem.denominations)}
                                        <img src="/images/coins/vndc.png" alt="logo" style={{ width: "16px", height: "16px" }} />
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Tương đương
                                    </Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "5px" }}>
                                        {handleFormat(payItem.totalPrice)}
                                        <img src="/images/coins/vndc.png" alt="logo" style={{ width: "16px", height: "16px" }} />
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Lợi suất kỳ này
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {handleFormat(payItem.currentYield)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">
                                        Cần thanh toán
                                    </Text>
                                    <Text fontSize="14px" color="text">
                                        {handleFormat(payItem.currentYield)}
                                    </Text>
                                </Flex>
                            </CsBoxInfo>
                        </WrapItemMobile>
                    </>
                )}
            </Box>

            <CsFlex>
                <CsBox>
                    <CsInfo>Thông tin chi tiết</CsInfo>
                    <Flex width="100%" alignItems="center" justifyContent="space-between">
                        <CsTextModalLabel>{t("Contract")}</CsTextModalLabel>
                        <CustomLink
                            style={{ textAlign: "right" }}
                            onClick={() => handleOpenLink(payItem.nftAddress)}
                        >
                            <Text fontWeight="600">{sAccount(payItem.nftAddress)}</Text>
                            <OpenNewIconFundgo />
                        </CustomLink>
                    </Flex>
                    <Flex mt={["1rem", , "1.5rem"]} width="100%" alignItems="center" justifyContent="space-between">
                        <CsTextModalLabel>{t("Thông báo")}</CsTextModalLabel>
                        <CsTextNotice fontWeight="600">
                            Còn {payItem.dayDiff} {UNITDATE} đến {UNITDATE} trả lợi suất kế tiếp
                        </CsTextNotice>
                    </Flex>
                </CsBox>

                {/* Right */}
                <CsBox>
                    <CsFlexLeft width="100%">
                        <CsTextModalLabel>Số dư của ví</CsTextModalLabel>
                        <Flex flexDirection="column" alignItems="flex-end">
                            <Flex alignItems="center">
                                <CsTextModalValuePrice>{handleFormat(userBalanceOf)}</CsTextModalValuePrice>
                                <UnitPrice />
                            </Flex>
                            <ExchangeToken price={userBalanceOf} />
                        </Flex>
                    </CsFlexLeft>
                    <CsFlexRight width="100%">
                        <CsTextModalLabel>Cần thanh toán</CsTextModalLabel>
                        <Flex flexDirection="column" alignItems="flex-end">
                            <Flex alignItems="center">
                                <CsTextModalValuePrice>
                                    {handleFormat(payItem.currentYield)}
                                </CsTextModalValuePrice>
                                <UnitPrice />
                            </Flex>
                            <ExchangeToken price={payItem.currentYield} />
                        </Flex>

                    </CsFlexRight>
                </CsBox>
            </CsFlex>

            <Flex width="100%" justifyContent="center" mt={["2rem", , , "3rem"]}>
                {
                    isNotAllowance && <CsButtonConfirm
                        disabled={pendingTx || isNotBalance}
                        endIcon={pendingTx && <AutoRenewIcon spin color="textSubtle" />}
                        onClick={() => handleApprove(payItem.nftAddress)}
                    >
                        Chấp thuận
                    </CsButtonConfirm>
                }
                {
                    !isNotAllowance && <CsButtonConfirm
                        disabled={pendingTx || isNotBalance || currentTime < payItem?.paydayNextProfit}
                        endIcon={pendingTx && <AutoRenewIcon spin color="textSubtle" />}
                        onClick={async () => {
                            const { status } = await handleConfirm(props.paydayNextProfit || payItem.paydayNextProfit);
                            if (status === 200) {
                                props.onDismiss();
                            }
                        }}
                    >
                        Xác nhận thanh toán lợi suất
                    </CsButtonConfirm>
                }
            </Flex>
            {isNotBalance &&
                <Flex width="100%" justifyContent="center" mt="1rem">
                    <Text small color="red">Số dư VNDC không đủ</Text>
                </Flex>
            }
            <ContainerContract title="Bằng cách “Xác nhận thanh toán lợi suất” tôi đồng ý với " />
        </CsModal>
    );
};

export default PayModal;

const handleFormat = (value) =>
    Number(value).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 });

const CsInfo = styled(Flex)`
    color: #02111B;
    font-size: 14px;
    margin-bottom: 24px;
    font-weight: 600;
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
    }
`

const CsBoxInfo = styled(Flex)`
    gap: 12px;
    width: 100%;
    height: 170px;
    overflow-y: scroll;
    padding-right: 10px;
    flex-direction: column;
    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.primaryBright};
        border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f4f4f5;
        border-radius: 10px;
    }
`;

const CsFlexAgree = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    ${Text}{
        color: ${({ theme }) => theme.colors.text};
        font-weight: 600;
    }
`

const CsTextNotice = styled(Text)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.red};
    @media screen and (max-width: 768px) {
        font-size: 12px;
        max-width: 50%;
        text-align: right;
    }
`;
const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 20px;
        letter-spacing: 0;
    }
`;

const CsBox = styled(Box)`
    width: 45%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const CsButtonConfirm = styled(Button) <{ disabled: boolean }>`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    transition: 0.5s;
    &:hover {
        background: ${({ theme, isWarning, disabled }) => (isWarning ? "transparent" : disabled ? theme.colors.backgroundDisabled : theme.colors.primary)};
        color: ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.red : disabled ? theme.colors.textSubtle : theme.colors.white)};
    }
    @media screen and (max-width: 1000px) {
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`;
const CsLink = styled.div`
    text-decoration: underline;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 18px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
    cursor: pointer;
`;

const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 2rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-top: 1rem;
    }
`;

const WrapperCard = styled(Flex)`
    width: 100%;
    padding: 0 5px;
    gap: 15px;
    height: 60px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        text-align: center;
        font-size: 13px;
    }
`;
const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 14px 0;
    ${Text} {
        text-align: center;
        font-size: 13px;
        line-height: 1.5;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
