import BigNumber from "bignumber.js";
import { PAY_DAY_NEXT_PROFIT, UNITDATE, UNITDIFF } from "config";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { BASE_RATE_INTERVEST } from "config/constants";
import moment from "moment";
import { AssetItems, IntervestRate, ListIntervestRate, ListUserAsset, ListUserBalance, WrappedItems, itemListing } from "state/asset/type";
import multicall from "utils/multicall";
import { isTrustPay } from "hooks/isTrustPay";
import { BalanceOrder, ListResultBalance } from "./type";

export const fetchTotalItems = async (contractAddress: string, chainId: number): Promise<{ totalItems: number }> => {
    if (contractAddress?.length) {
        try {
            const calls = [
                {
                    address: contractAddress,
                    name: 'getTotalItems',
                    params: []
                }
            ]
            const results = await multicall(marketplaceAbi, calls, chainId)
            const convertResult = Number(new BigNumber(results?.toString()).toString())
            return {
                totalItems: convertResult
            }
        }
        catch (error) {
            console.log(error)
            return {
                totalItems: 0
            }
        }
    }
    return {
        totalItems: 0
    }
}
export const fetchIntervestTermRate = async (resulListListing: itemListing[], chainId: number): Promise<ListIntervestRate> => {
    try {
        if (resulListListing?.length > 0) {
            const callsIntervest = []
            for (let index = 0; index < resulListListing?.length; index++) {
                callsIntervest.push(
                    {
                        address: resulListListing[index]?.nftAddress,
                        name: 'intervestTermRate',
                        params: []
                    }
                )
            }
            const resultIntervest = await multicall(ccqWrappedAbi, callsIntervest, chainId)
            const result = []
            if (resultIntervest?.length) {
                for (let index = 0; index < resultIntervest?.length; index++) {
                    result.push({
                        profit: new BigNumber(resultIntervest[index].toString()).dividedBy(BASE_RATE_INTERVEST).multipliedBy(10).toString(),
                        nftAddress: resulListListing[index]?.nftAddress,
                    })
                }
            }
            return {
                listIntervestRate: result
            }
        }
        return {
            listIntervestRate: []
        }
    } catch (error) {
        return {
            listIntervestRate: []
        }
    }
}
export const fetchListingItems = async (contractAddress: string, totalItems: number, account: string, chainId: number): Promise<{ listListing: itemListing[] }> => {
    if (totalItems) {
        try {
            const calls = []
            for (let index = 0; index < totalItems; index++) {
                calls.push({
                    address: contractAddress,
                    name: 'items',
                    params: [index]
                })
            }
            const resultItems = await multicall(marketplaceAbi, calls, chainId)
            const listItem = []
            for (let indexListItem = 0; indexListItem < resultItems?.length; indexListItem++) {
                listItem.push({
                    indexListItem,
                    id: Number(new BigNumber(resultItems[indexListItem]?.id?.toString()).toString()),
                    nftId: new BigNumber(resultItems[indexListItem]?.nftId?.toString()).toString(),
                    nftAddress: resultItems[indexListItem]?.nftAddress,
                    amount: new BigNumber(resultItems[indexListItem]?.amount?.toString()).toString(),
                    seller: resultItems[indexListItem]?.seller,
                    nftContract: resultItems[indexListItem]?.nftContract,
                })
            }
            const filterById = listItem?.filter((item) => item?.seller?.toLowerCase() === isTrustPay(account).toLowerCase())
            return {
                listListing: filterById
            }
        }
        catch (error) {
            return {
                listListing: []
            }
        }
    }
    return {
        listListing: []
    }
}
export const fetchMarketPlaceAssest = async (resulListListing: itemListing[], contractMarketplaceAddress: string, chainId: number, account: string): Promise<ListUserAsset> => {
    try {
        if (resulListListing?.length > 0) {
            const calls = [];

            for (let index = 0; index < resulListListing?.length; index++) {
                calls.push({
                    address: resulListListing[index]?.nftAddress,
                    name: 'getMyAsset',
                    params: [contractMarketplaceAddress]
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const data = []
            for (let index = 0; index < result?.length; index++) {
                for (let subIndex = 0; subIndex < result[index][0]?.length; subIndex++) {
                    data.push(
                        {
                            nftAddress: resulListListing[index]?.nftAddress,
                            nftId: Number(new BigNumber(result[index][0][subIndex]?.toString()).toString())
                        }
                    )
                }
            }
            const resultData = data.filter((value, index, self) => index === self.findIndex((obj) => obj.nftAddress === value.nftAddress && obj.nftId === value.nftId));

            return {
                listUserAsset: resultData
            }
        }
        return {
            listUserAsset: []
        }
    } catch (error) {
        return {
            listUserAsset: []
        }
    }
}
export const getMarketPlaceBalance = async (resulListListing: itemListing[], contractMarketplaceAddress: string, chainId: number): Promise<ListResultBalance> => {
    try {
        if (contractMarketplaceAddress?.length > 0) {
            const calls = []
            for (let index = 0; index < resulListListing?.length; index++) {
                calls.push(
                    {
                        address: resulListListing[index].nftAddress,
                        name: 'balanceOf',
                        params: [contractMarketplaceAddress, resulListListing[index]?.nftId]
                    }
                )
            }
            const resultBalance = await multicall(ccqWrappedAbi, calls, chainId)
            const result = [];
            if (resultBalance?.length) {
                for (let index = 0; index < resultBalance?.length; index++) {
                    result.push({
                        nftAddress: resulListListing[index].nftAddress,
                        nftId: resulListListing[index].nftId,
                        balance: Number(new BigNumber(resultBalance[index]).toString()).toString(),
                    })
                }
            }
            return {
                listResultBalance: result
            }
        }
        return {
            listResultBalance: []
        }
    } catch (error) {
        return {
            listResultBalance: []
        }
    }
}

export const fetchUserBalanceOrder = async (
    contractMarketplaceAddress: string,
    listResultBalance: BalanceOrder[],
    listUserAsset: AssetItems[],
    listWrappedItem: WrappedItems[],
    listIntervestRate: IntervestRate[],
    resulListListing: itemListing[],
    totalListing: number,
    account: string,
    chainId: number,
): Promise<ListUserBalance> => {
    const currTime = Date.now()
    const currentTime = Math.floor(Date.now() / 1000);
    try {
        const getInfoOfNft = async () => {
            if (resulListListing?.length > 0) {
                const calls = [];
                for (let index = 0; index < resulListListing?.length; index++) {
                    calls.push({
                        address: resulListListing[index]?.nftAddress,
                        name: 'etfInfor',
                        params: []
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }

        const getBalanceOf = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'balanceOf',
                        params: [isTrustPay(account), index]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getCurrentPrice = async () => {
            if (resulListListing?.length) {
                try {
                    const calls = [];
                    for (let index = 0; index < resulListListing?.length; index++) {
                        calls.push({
                            address: resulListListing[index]?.nftAddress,
                            name: 'getPriceAtTime',
                            params: [currentTime]
                        })
                    }
                    return await multicall(ccqWrappedAbi, calls, chainId)
                } catch (e) {
                    console.log(e)
                    return null
                }
            }
            return null
        };
        const getVestingMap = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'getMyVestList',
                        params: [contractMarketplaceAddress, listUserAsset[index]?.nftId]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        };
        const [resultGetInfoOfNft, resultBalanceOf, resultGetCurrentPrice, resultMyVestList] = await Promise.all([
            getInfoOfNft(),
            getBalanceOf(),
            getCurrentPrice(),
            getVestingMap()
        ]);

        const totalBalanceOf = resultBalanceOf.reduce((acc, pre) => {
            return acc + new BigNumber(pre.toString()).toNumber()
        }, 0);

        const data = listResultBalance?.map((list: any, index) => {
            const itemProfit = listIntervestRate?.find((item) => item?.nftAddress?.toLowerCase() === list?.nftAddress?.toLowerCase())
            const profitPercent = new BigNumber(itemProfit?.profit || 0).dividedBy(10).multipliedBy(10).toString();
            const dataVestingMap = []
            if (resultMyVestList?.length > 0) {
                for (let vestingMap = 0; vestingMap < resultMyVestList[0][index]?.length; vestingMap++) {
                    dataVestingMap.push(
                        {
                            vestAmount: new BigNumber(resultMyVestList[0][index][vestingMap]?.amount.toString()).toString(),
                            isVested: resultMyVestList[0][index][vestingMap]?.isVested,
                            nextInterestPaymentDate: Number(new BigNumber(resultMyVestList[0][index][vestingMap]?.vestDate?.toString()).toString()),
                            intervestPayed: new BigNumber(resultMyVestList[0][index][vestingMap]?.intervestPayed?.toString()).toString(),
                            index: vestingMap
                        }
                    )
                }
            }

            const nextVestingMap = dataVestingMap.find((d) => currTime < d?.nextInterestPaymentDate * 1000);
            const checkDayDiff = nextVestingMap ? moment(moment(Number(nextVestingMap?.nextInterestPaymentDate) * 1000)).diff(moment(Number(Date.now())), UNITDIFF) : -1
            const convertCheckDayDiff = checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1
            const paydayNextProfit = nextVestingMap ? nextVestingMap.nextInterestPaymentDate : 0;
            return {
                ...list,
                idMarketPlace: resulListListing[index]?.id,
                totalListing: Number(resulListListing[index]?.amount),
                indexListItem: resulListListing[index]?.indexListItem,
                ccqName: resultGetInfoOfNft[index]?.name,
                profit: profitPercent,
                issueDate: new BigNumber(resultGetInfoOfNft[index]?.issueDate?.toString()).toString(),
                expireDate: new BigNumber(resultGetInfoOfNft[index]?.expireDate?.toString()).toString(),
                intervestTerm: Number(new BigNumber(resultGetInfoOfNft[index]?.intervestTerm?.toString()).toString()) === 92 ? 3 : 6,
                price: new BigNumber(resultGetInfoOfNft[index]?.price?.toString()).dividedBy(1e18).toString(),
                publisher: resultGetInfoOfNft[index]?.publisher,
                totalSupply: new BigNumber(resultGetInfoOfNft[index]?.totalSupply?.toString()).toString(),
                unitPrice: new BigNumber(resultGetCurrentPrice[index]?.toString()).dividedBy(1e18).toString(),
                vestingMap: dataVestingMap,
                nextInterestPaymentDate: paydayNextProfit,
                formatNextInterestPaymentDate: paydayNextProfit ? moment(paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT) : "-",
                formatDayDiff: convertCheckDayDiff <= 0 ? 0 : `${convertCheckDayDiff}`,
                dayDiff: checkDayDiff !== -1 ? checkDayDiff : 0,
                isDayDiff: checkDayDiff !== 0,
                tokenId: index,
                remainingAmount: listResultBalance[index]?.balance,
                MyAssetNftId: listUserAsset[index]?.nftId,
                totalBalanceOf

            }
        });
        return {
            listUserBalance: data as any[]
        }
    } catch (error) {
        console.log(error)
        return {
            listUserBalance: []
        }
    }
}
