import BigNumber from "bignumber.js";
import { PAY_DAY_NEXT_PROFIT, UNITDATE, UNITDIFF } from "config";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { BASE_RATE_INTERVEST } from "config/constants";
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type";
import moment from 'moment';
import multicall from "utils/multicall";

import { isTrustPay } from "hooks/isTrustPay";
import { AssetItems, Balance, IntervestRate, itemListing, ListIntervestRate, ListResultBalance, ListUserAsset, ListUserBalance, ListWrapppedItems, WrappedItems } from "./type";

export const fetchDataItems = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<ListWrapppedItems> => {
    try {
        if (account?.length > 0) {
            const calls = [];
            for (let index = 0; index < ccqConfig?.length; index++) {
                calls.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'etfInfor',
                    params: []
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const listItems = []
            if (result?.length > 0) {
                for (let index = 0; index < result?.length; index++) {
                    listItems.push({
                        nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                        expireDate: Number(new BigNumber(result[index]?.expireDate.toString()).toString()),
                        intervestTerm: Number(new BigNumber(result[index]?.intervestTerm.toString()).toString()) === 92 ? 3 : 6, // 92 day => 3 months, 184 day => 6 months
                        issueDate: Number(new BigNumber(result[index]?.issueDate.toString()).toString()),
                        ccqName: result[index]?.name,
                        denominations: Number(new BigNumber(result[index]?.price.toString()).dividedBy(1E18).toString()),
                        publisher: result[index]?.publisher,
                        totalSupply: Number(new BigNumber(result[index]?.totalSupply.toString()).toString()),
                    })
                }
            }
            return {
                listWrappedItem: listItems
            }
        }
        return {
            listWrappedItem: []
        }
    }
    catch (_) {
        return {
            listWrappedItem: []
        }
    }
}
export const fetchUserAssest = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<ListUserAsset> => {
    try {
        if (account?.length > 0) {
            const calls = [];
            for (let index = 0; index < ccqConfig?.length; index++) {

                calls.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'getMyAsset',
                    params: [isTrustPay(account)]
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const data = []
            for (let index = 0; index < result?.length; index++) {
                for (let subIndex = 0; subIndex < result[index][0]?.length; subIndex++) {
                    data.push(
                        {
                            nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                            nftId: Number(new BigNumber(result[index][0][subIndex]?.toString()).toString())
                        }
                    )
                }
            }
            return {
                listUserAsset: data
            }
        }
        return {
            listUserAsset: []
        }
    }
    catch (error) {
        return {
            listUserAsset: []
        }
    }
}
export const fetchIntervestTermRate = async (ccqConfig: ccqSupportItems[], chainId: number): Promise<ListIntervestRate> => {
    try {
        if (ccqConfig?.length > 0) {
            const callsIntervest = []
            for (let index = 0; index < ccqConfig?.length; index++) {
                callsIntervest.push(
                    {
                        address: ccqConfig[index]?.ccqWrrapped?.address,
                        name: 'intervestTermRate',
                        params: []
                    }
                )
            }
            const resultIntervest = await multicall(ccqWrappedAbi, callsIntervest, chainId)
            const result = []
            if (resultIntervest?.length) {
                for (let index = 0; index < resultIntervest?.length; index++) {
                    result.push({
                        profit: new BigNumber(resultIntervest[index].toString()).dividedBy(BASE_RATE_INTERVEST).multipliedBy(10).toString(),
                        nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                    })
                }
            }
            return {
                listIntervestRate: result
            }
        }
        return {
            listIntervestRate: []
        }
    } catch (error) {
        return {
            listIntervestRate: []
        }
    }
}
export const getBalance = async (listUserAsset: AssetItems[], account: string, chainId: number): Promise<ListResultBalance> => {
    try {
        if (account?.length > 0) {
            const calls = []
            for (let index = 0; index < listUserAsset?.length; index++) {
                calls.push(
                    {
                        address: listUserAsset[index].nftAddress,
                        name: 'balanceOf',
                        params: [isTrustPay(account), listUserAsset[index]?.nftId]
                    }
                )
            }
            const resultBalance = await multicall(ccqWrappedAbi, calls, chainId)
            const result = []
            if (resultBalance?.length) {
                for (let index = 0; index < resultBalance?.length; index++) {
                    result.push({
                        nftAddress: listUserAsset[index].nftAddress,
                        nftId: listUserAsset[index].nftId,
                        balance: new BigNumber(resultBalance[index]).toString()
                    })
                }
            }
            return {
                listResultBalance: result
            }
        }
        return {
            listResultBalance: []
        }
    } catch (error) {
        return {
            listResultBalance: []
        }
    }
}
export const fetchTotalListing = async (listUserAsset: AssetItems[], contractAddress: string, account: string, chainId: number): Promise<{ totalListing: itemListing[] }> => {
    if (contractAddress?.length) {
        try {
            const calls = [];
            const listItem = [];
            const results = await multicall(marketplaceAbi, [{
                address: contractAddress,
                name: 'getTotalItems',
                params: []
            }], chainId);

            const convertResult = Number(new BigNumber(results?.toString()).toString())
            for (let index = 0; index < convertResult; index++) {
                calls.push({
                    address: contractAddress,
                    name: 'items',
                    params: [index]
                })
            }
            const resultItems = await multicall(marketplaceAbi, calls, chainId)
            for (let indexListItem = 0; indexListItem < resultItems?.length; indexListItem++) {
                listItem.push({
                    indexListItem,
                    id: Number(new BigNumber(resultItems[indexListItem]?.id?.toString()).toString()),
                    nftId: new BigNumber(resultItems[indexListItem]?.nftId?.toString()).toString(),
                    nftAddress: resultItems[indexListItem]?.nftAddress,
                    amount: new BigNumber(resultItems[indexListItem]?.amount?.toString()).toString(),
                    seller: resultItems[indexListItem]?.seller,
                    nftContract: resultItems[indexListItem]?.nftContract,
                })
            }
            const filterById = listItem?.filter((item: itemListing) => item?.seller?.toLowerCase() === isTrustPay(account).toLowerCase());
            return {
                totalListing: filterById
            }
        }
        catch (_) {
            return {
                totalListing: []
            }
        }
    }
    return {
        totalListing: []
    }
}

export const fetchUserBalance = async (
    contractMarketplaceAddress: string,
    listUserAsset: AssetItems[],
    listResultBalance: Balance[],
    listWrappedItem: WrappedItems[],
    listIntervestRate: IntervestRate[],
    totalListing: itemListing[],
    account: string,
    chainId: number
): Promise<ListUserBalance> => {
    const currTime = Date.now()
    const currentTime = Math.floor(Date.now() / 1000);
    try {

        const getApproveForAll = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'isApprovedForAll',
                        params: [isTrustPay(account), contractMarketplaceAddress]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        };

        const getVestingMap = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'getMyVestList',
                        params: [isTrustPay(account), listUserAsset[index]?.nftId]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        };

        const getCurrentPrice = async () => {
            if (listUserAsset?.length) {
                try {
                    const calls = [];
                    for (let index = 0; index < listUserAsset?.length; index++) {
                        calls.push({
                            address: listUserAsset[index]?.nftAddress,
                            name: 'getPriceAtTime',
                            params: [currentTime]
                        })
                    }
                    return await multicall(ccqWrappedAbi, calls, chainId)
                } catch (_) {
                    return null
                }
            }
            return null
        };

        const [resultVestingMap, resultGetApproveForAll, resultGetCurrentPrice] = await Promise.all([
            getVestingMap(),
            getApproveForAll(),
            getCurrentPrice(),
        ])

        const data = [];
        for (let index = 0; index < listResultBalance?.length; index++) {
            const assetItem = listWrappedItem.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase())
            const itemProfit = listIntervestRate?.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase())
            const findTotalListing = totalListing?.filter((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase())

            const sumTotalisting = findTotalListing?.reduce((curr, prev) => {
                return curr + (Number(prev?.amount))
            }, 0)
            const dataVestingMap = []
            for (let vestingMap = 0; vestingMap < resultVestingMap[index][0]?.length; vestingMap++) {
                dataVestingMap.push(
                    {
                        vestAmount: new BigNumber(resultVestingMap[index][0][vestingMap]?.amount?.toString()).toString(),
                        isVested: resultVestingMap[index][0][vestingMap]?.isVested,
                        nextInterestPaymentDate: Number(new BigNumber(resultVestingMap[index][0][vestingMap]?.vestDate?.toString()).toString()),
                        intervestPayed: new BigNumber(resultVestingMap[index][0][vestingMap]?.intervestPayed?.toString()).toString(),
                        index: vestingMap
                    }
                )
            }
            const nextVestingMap = dataVestingMap?.find((d) => currTime < d?.nextInterestPaymentDate * 1000);
            const checkDayDiff = nextVestingMap ? moment(moment(Number(nextVestingMap?.nextInterestPaymentDate) * 1000)).diff(moment(Number(Date.now())), UNITDIFF) : -1;
            const convertCheckDayDiff = checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1

            const paydayNextProfit = nextVestingMap ? nextVestingMap?.nextInterestPaymentDate : 0;
            data.push(
                {
                    tokenId: index,
                    nftBalance: Number(listResultBalance[index]?.balance),
                    expireDate: assetItem?.expireDate,
                    intervestTerm: assetItem?.intervestTerm,
                    issueDate: assetItem?.issueDate,
                    ccqName: assetItem?.ccqName,
                    denominations: new BigNumber(assetItem?.denominations?.toString()).toString(),
                    publisher: assetItem?.publisher,
                    totalSupply: assetItem?.totalSupply,
                    nftAddress: assetItem?.nftAddress,
                    nftId: listResultBalance[index]?.nftId,
                    isExpire: currTime > assetItem?.expireDate * 1000,
                    profit: Number(itemProfit?.profit),
                    totalListing: sumTotalisting,
                    unitPrice: new BigNumber(resultGetCurrentPrice[index]?.toString()).dividedBy(1e18).toString(),
                    isApprovedForAll: resultGetApproveForAll[index][0],
                    vestingMap: dataVestingMap,
                    dayDiff: convertCheckDayDiff !== 0 ? convertCheckDayDiff : 0,
                    formatDayDiff: convertCheckDayDiff !== 0 ? `${convertCheckDayDiff} ${UNITDATE}` : ` 0 ${UNITDATE}`,
                    nextInterestPaymentDate: paydayNextProfit,
                    formatNextInterestPaymentDate: paydayNextProfit ? moment(paydayNextProfit * 1000).format(PAY_DAY_NEXT_PROFIT) : "-",
                    isDayDiff: checkDayDiff !== 0,
                }
            )
        }
        const filterAccount = data.filter(item => item.publisher.toLowerCase() === isTrustPay(account).toLowerCase());
        const data2 = [];
        for (let index = 0; index < filterAccount?.length; index++) {
            // eslint-disable-next-line no-await-in-loop
            const result = await fetchDataGetIntervest(filterAccount[index], chainId);
            data2.push({
                ...result
            })
        }
        return {
            listUserBalance: data2.filter((item) => Number(item?.nftBalance) !== 0)
        }
    } catch (error) {
        return {
            listUserBalance: []
        }
    }
};

const indexLastMonthIntervest = (present: number) => {
    if (present === 0)
        return present;
    return present - 1;
}

const fetchDataGetIntervest = async (data, chainId) => {
    try {
        const { vestingMap, nextInterestPaymentDate } = data;
        const checkPresentIntervest = vestingMap.findIndex(v => v.nextInterestPaymentDate === nextInterestPaymentDate);
        const IndexPresentIntervest = checkPresentIntervest !== -1 ? checkPresentIntervest : vestingMap.length;
        const indexLastMonth = indexLastMonthIntervest(IndexPresentIntervest);

        const callListVesting = vestingMap?.map((_, index) => ({
            address: data?.nftAddress,
            name: 'getIntervest',
            params: [data?.publisher, index]
        }));
        const response = await multicall(ccqWrappedAbi, callListVesting, chainId);
        const payload = vestingMap?.map((item, index) => {
            return {
                ...item,
                profit: Number(new BigNumber(response?.[index])?.dividedBy(1E18).toString())
            }
        });

        return {
            ...data,
            checkIsVested: payload[indexLastMonth].nextInterestPaymentDate * 1000 < Date.now() && payload[indexLastMonth].profit !== 0,
            vestingMap: payload,
            intervest: payload[indexLastMonth].profit
        }
    } catch (_) {
        return [];
    }
}

