import { Button, Flex, Modal, ModalBody, Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export const ContainerHeader = styled(Flex)`
    width: 100%;
    height: 71px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.textDisabled};
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;
export const StyCellSmall = styled(Flex)`
    width: 6%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${Text} {
        width: 100%;
        text-align: center;
        font-size: 13px;
        @media screen and (min-width: 1000px) and (max-width: 1280px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1000px) {
            text-align: initial;
            width: auto;
            font-size: 16px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.text};
        }
    }
    > img {
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
    }
`;
export const StyCellSTT = styled(Flex)`
    width: 9.3%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${Text} {
        width: 100%;
        text-align: center;
        font-size: 13px;
        @media screen and (min-width: 1000px) and (max-width: 1280px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1000px) {
            text-align: initial;
            width: auto;
            font-size: 14px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.text};
        }
    }
    > img {
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
    }
`;
export const StyCellSttName = styled(Flex)`
    width: 9.3%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${Text} {
        width: 100%;
        text-align: center;
        font-size: 13px;
        @media screen and (min-width: 1000px) and (max-width: 1280px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1000px) {
            text-align: initial;
            width: auto;
            font-size: 14px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.text};
        }
    }
`;
export const StyCellPrice = styled(Flex)`
    width: 11.14%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${Text} {
        width: 100%;
        text-align: center;
        font-size: 13px;
        @media screen and (min-width: 1000px) and (max-width: 1280px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1000px) {
            text-align: initial;
            width: auto;
            font-size: 16px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.text};
        }
    }
    > img {
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
    }
`;
export const CsButton = styled(Button) <{ isWarning: boolean; disabled: boolean }>`
    min-width: 130px;
    height: 36px;
    font-size: 14px;
    padding: 0px;
    border-radius: 4px;
    background: ${({ theme, isWarning }) => (isWarning ? theme.colors.red : "transparent")};
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.white : theme.colors.primary)};
    border: 1px solid ${({ theme, isWarning }) => (!isWarning ? theme.colors.primary : theme.colors.red)};
    &:hover {
        background: ${({ theme, isWarning, disabled }) => (isWarning ? "transparent" : disabled ? theme.colors.backgroundDisabled : theme.colors.primary)};
        color: ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.red : disabled ? theme.colors.textSubtle : theme.colors.white)};
    }
    @media screen and (max-width: 1399px) {
        width: 100%;
        padding: 0px;
        font-size: 13px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 80%;
        padding: 0px;
        /* font-size: 14px; */
        max-width: 120px;
    }
    @media screen and (max-width: 600px) {
        height: 32px;
        font-size: 12px;
        max-width: 120px;
    }
`;
export const CsButtonDisabled = styled(Button)`
    min-width: 130px;
    height: 36px;
    font-size: 14px;
    padding: 0px;
    border-radius: 4px;
    background: transparent !important;
    color: ${({ theme }) => theme.colors.textSubtle};
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    cursor: not-allowed;
    @media screen and (max-width: 1399px) {
        width: 100%;
        padding: 0px;
        font-size: 12px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 80%;
        padding: 0px;
        /* font-size: 14px; */
        max-width: 120px;
    }
    @media screen and (max-width: 600px) {
        height: 32px;
        max-width: 120px;
        font-size: 12px;
    }
`;
export const CsLabelMobile = styled(Text)`
    color: ${({ theme }) => theme.colors.textSubtle} !important;
    font-weight: 400 !important;
    @media screen and (min-width: 1000px) {
        display: none;
    }
`;
export const ContainerRowMobile = styled(Flex)`
    width: 100%;
    flex-direction: column;
    height: auto;
    gap: 12px;
    padding: 10px 0;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
`;
export const CsCellMobile = styled(Flex)`
    /* width: 30%; */
    height: 100%;
    align-items: center;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 25%;
    }
    ${Text} {
        width: 100%;
        text-align: start;
        font-size: 12px;
        font-weight: 500;
    }
`;
export const CsCellMobileLarge = styled(Flex)`
    width: 33.34%;
    height: 100%;
    align-items: center;
    ${Text} {
        width: 100%;
        text-align: start;
        font-size: 14px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 25%;
    }
`;

export const ContainerHeaderModal = styled(Flex)`
    width: 100%;
    height: 48px;
    align-items: center;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;
export const Col = styled(Flex)`
    width: 48%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
export const ContainerWrapped = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    height: auto;
    margin-top: 1.5rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
        gap: 14px;
    }
`;
export const ContainerWrapper = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 12px;
    }
    @media screen and (max-width: 600px) {
        width: auto;
        flex-wrap: wrap;
        gap: 12px;
    }
`;
export const ContainerRowModal = styled(Flex)`
    width: 100%;
    height: 48px;
    align-items: center;
    background-color: #f4f4f5;
    border-radius: 8px;
    @media screen and (max-width: 1000px) {
        height: auto;
        flex-wrap: wrap;
        gap: 12px;
        background: transparent;
    }
`;
export const ContainerTable = styled(Flex)`
    width: 100%;
    height: 100%;
    gap: 10px;
    flex-direction: column;
`;
export const CsTextMobile = styled(Text)`
    @media screen and (min-width: 1001px) {
        display: none;
    }
    @media screen and (max-width: 1000px) {
        text-align: left !important;
    }
`;
export const StyCellModal = styled(Flex)`
    width: 12.5%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${Text} {
        width: 100%;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 1000px) {
            text-align: right;
            width: auto;
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
    }
`;
export const CsModalUnlist = styled(Modal)`
    width: 1024px !important;
    padding-bottom: 10px;
    ${ModalBody} {
        max-height: 85vh;
    }
    @media screen and (min-width: 1001px) and (max-width: 1440px) {
        width: 990px !important;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 550px !important;
    }
    @media screen and (max-width: 600px) {
        width: 325px;
    }
`;
export const MobileCellLarge = styled(Flex)`
    width: 40%;
    height: 100%;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 25%;
    }
    align-items: center;
    ${Text} {
        width: 100%;
        text-align: start;
        font-size: 12px;
        font-weight: 600;
        color: #02111B;
    }
`;
export const MobileCellSmall = styled(Flex)`
    width: 20%;
    height: 100%;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 25%;
    }
    align-items: center;
    ${Text} {
        width: 100%;
        font-size: 12px;
        font-weight: 600;
    }
`;

export const CsText = styled(Text) <{ isBuy: boolean }>`
    color: ${({ theme, isBuy }) => (isBuy ? theme.colors.success2 : theme.colors.red)};
    font-weight: 700;
    text-align: right;
`;

export const CsTitleModal = styled(Text)`
    font-size: 34px;
    @media screen and (max-width: 586px) {
        font-size: 24px;
    }
`;
export const CsFlexAgree = styled(Flex)`
    width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: column;
    ${Text} {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.text};
        @media screen and (min-width: 601px) and (max-width: 1000px) {
            font-size: 18px;
        }
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
`;
export const CsButtonConfirm = styled(Button)`
    height: 65px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 1.5;
        height: 40px;
        min-width: 50%;
        border-radius: 4px;
    }
`;
export const CsLink = styled.div`
    text-decoration: underline;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 18px;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
    cursor: pointer;
`;
export const TextAddr = styled(Text)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textSubtle};
`;
export const WrapAddress = styled.div`
    height: 40px;
    min-width: 220px;
    margin-left: 40px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    @media screen and (max-width: 1024px) {
        min-width: 120px;
    }
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: ${({ theme }) => theme.colors.text};
    }
`;
export const CsFlexWrapInfo = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    @media screen and (max-width: 1024px) {
        margin-top: 2rem;
        flex-direction: column;
    }
`;
export const CSInfoSide = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    width: 40%;
    ${Text} {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

export const WrapperCard = styled(Flex)`
    width: 100%;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        line-height: 50px;
        text-align: center;
        padding: 10px 0;
    }
`;
export const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 48px 0 24px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
export const WrapItemMobile = styled.div`
    padding: 12px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    ${Text} {
        font-size: 12px;
        font-weight: 600;
    }
    @media screen and (max-width: 600px) {
        /* padding: 12px 0px 12px 0px; */
    }
`;
export const CsModal = styled(Modal)`
    padding: 24px 0;
    min-width: 990px;
    @media screen and (max-width: 768px) {
        min-width: 90%;
        padding: 0;
    }
`;
export const CsButtonTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 34px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export const CsButtonCancel = styled(Button)`
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    height: 65px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 768px) {
        height: 40px;
        font-size: 14px;
        min-width: 50%;
        border-radius: 4px;
    }
`;
export const CsTextModalDetail = styled(Text)`
    font-size: 16px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
export const CsTextModalLabel = styled(Text)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textSubtle};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
export const CsTextModalValue = styled(Text)`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
export const CsTextModalValuePrice = styled(Text)`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

export const CustomLink = styled(Flex)`
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${Text} {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text};
        @media screen and (min-width: 601px) and (max-width: 1000px) {
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            font-weight: 600;
        }
    }
`;

export const CsFlexLeft = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    margin-top: 0rem;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        margin-top: 1rem;
    }
    @media screen and (max-width: 600px) {
        margin-top: 1rem;
    }
`;
export const CsFlexRight = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        margin-top: 1rem;
    }
    @media screen and (max-width: 600px) {
        margin-top: 1rem;
    }
`;

export const CsImgCoin = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    display: none;
    @media screen and (max-width: 768px) {
        width: 16px;
        height: 16px;
        display: block;
    }
`;
export const CsTextVndc = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-size: 14px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
export const CsTextNotice = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red};
    @media screen and (max-width: 768px) {
        max-width: 70%;
        text-align: right;
    }
`;
export const TextLabel = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textSubtle};
`;
export const TextValue = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text};
`;
export const CsFlexLine = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;
export const CsTextDone = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.success2};
`;
