import styled from "styled-components";

import { Button } from "@pancakeswap/uikit";

export const CustomButton = styled(Button) <{ isWarning: boolean, disabled: boolean }>`
    height: 36px;
    font-size: 14px;
    padding: 8px 16px;
    border-radius:4px;
    display: flex;
    width: 100%;
    border: 1px solid ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.red : disabled ? theme.colors.primary400 : theme.colors.primary400)};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.primary400 : theme.colors.white)};
    background: ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.red : disabled ? theme.colors.white : theme.colors.primary)};
    &:disabled{
        background: ${({ theme }) => (theme.colors.white)};
        color: ${({ theme }) => (theme.colors.textSubtle)};
        border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    }
    &:hover{
        background: ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.white : disabled ? theme.colors.white : theme.colors.primary400)};
        color: ${({ theme, isWarning, disabled }) => (isWarning ? theme.colors.red : disabled ? theme.colors.textSubtle : theme.colors.white)};
    }
    @media screen and (max-width: 1399px) {
        width: 100%;
        padding: 0px;
        font-size: 13px;
        min-width: 100px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 80%;
        padding: 0px;
        /* font-size: 14px; */
        max-width: 120px;
    }
    @media screen and (max-width: 600px){
        height: 32px;
        font-size: 12px;
        max-width: 120px;
    }
`