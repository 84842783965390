import * as React from 'react';
import { Button, Flex } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { GetWhitelist } from "state/auth";

import styled from "styled-components";

const SubNav = ({ children }: { children?: React.ReactNode }) => {
    const router = useRouter()
    const windowSize = GetWindowSize()
    const data = GetWhitelist()

    const trustPayAsset = "/trust-pay/asset";
    const trustPayOrder = "/trust-pay/order";

    const fungoRelease = "/fungo/release";

    return (
        <>
            {windowSize > 600 ?
                <CsFlexRouter>
                    {
                        data?.dataUser?.type === 1 ?
                            <>
                                <CsCol60 style={{ width: "100%" }}>
                                    <Link href="/" passHref >
                                        <CsButtonManager variant={router?.pathname === "/" ? "primary" : "secondary"} >
                                            Quản lý
                                        </CsButtonManager>
                                    </Link>
                                    <Link href={fungoRelease} passHref >
                                        <CsButtonManager
                                            variant={router?.pathname?.toLowerCase() === fungoRelease ? "primary" : "secondary"}
                                        >
                                            Phát hành
                                        </CsButtonManager>
                                    </Link>
                                    {children}
                                </CsCol60>
                            </> :
                            <>
                                <CsCol60>
                                    <Link href="/" passHref >
                                        <CsButtonManager variant={router?.pathname === "/" ? "primary" : "secondary"} >
                                            Quản lý
                                        </CsButtonManager>
                                    </Link>
                                    <Link href={trustPayAsset} passHref >
                                        <CsButtonManager
                                            variant={router?.pathname?.toLowerCase() === trustPayAsset ? "primary" : "secondary"}
                                        >
                                            Tài sản
                                        </CsButtonManager>
                                    </Link>
                                    <Link href={trustPayOrder} passHref >
                                        <CsButtonManager
                                            variant={router?.pathname?.toLowerCase() === trustPayOrder ? "primary" : "secondary"}
                                        >
                                            Sổ lệnh
                                        </CsButtonManager>
                                    </Link>
                                </CsCol60>
                            </>
                    }
                </CsFlexRouter>
                :
                <CsFlexRouter>
                    {
                        data?.dataUser?.type === 1 ?
                            <>
                                <Col50>
                                    <Link href="/" passHref>
                                        <CsButtonMobile isActive={router?.pathname?.toLowerCase() === "/"} >Quản lý</CsButtonMobile>
                                    </Link>
                                </Col50>
                                <Col50>
                                    <Link href={fungoRelease} passHref>
                                        <CsButtonMobile isActive={router?.pathname?.toLowerCase() === fungoRelease} >Phát hành</CsButtonMobile>
                                    </Link>
                                </Col50>
                            </>
                            :
                            <>
                                <Col50>
                                    <Link href="/" passHref>
                                        <CsButtonMobile isActive={router?.pathname?.toLowerCase() === "/"}> Quản lý</CsButtonMobile>
                                    </Link>
                                </Col50>
                                <Col50>
                                    <Link href={trustPayAsset} passHref>
                                        <CsButtonMobile isActive={router?.pathname?.toLowerCase() === trustPayAsset} >Tài sản</CsButtonMobile>
                                    </Link>
                                </Col50>
                                <Col50>
                                    <Link href={trustPayOrder} passHref>
                                        <CsButtonMobile isActive={router?.pathname?.toLowerCase() === trustPayOrder} >Sổ lệnh</CsButtonMobile>
                                    </Link>
                                </Col50>
                            </>
                    }

                </CsFlexRouter >
            }
        </>

    )
}

export default SubNav

const CsFlexRouter = styled(Flex)`
    width: 100%;
    margin-top: 42px;
    justify-content: space-between;
    @media screen and (max-width: 600px){
        margin-top: 22px;
    }
`
const CsButtonManager = styled(Button)`
    padding: 11px 24px;
    width: 221px;
    height: 48px;
    font-weight: 700;
    @media screen and (min-width: 769px) and (max-width: 1280px) {
        width: 206px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 116px;
        padding: 0px;
    }
    @media screen and (max-width: 600px){
        width: 101px;
        padding: 0px;
        border-radius:8px;
    }
`
const CsButtonRight = styled(Button)`
    padding: 11px 24px;
    width: 221px;
    height: 48px;
    @media screen and (min-width: 769px) and (max-width: 1280px) {
        width: 156px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 116px;
        padding: 0px;
    }
    @media screen and (max-width: 600px){
        width: 101px;
        padding: 0px;
        border-radius:8px;
    }
`
const CsCol60 = styled(Flex)`
    width: 60%;
    height: 100%;
    gap:12px;
    justify-content: flex-start;
    align-items: center;
    a{
        text-decoration: none;
    }
    &:hover{
        a{
            text-decoration: none;
        }
    }
`
const CsCol40 = styled(Flex)`
    width: 40%;
    height: 100%;
    gap:12px;
    justify-content: flex-end;
    align-items: center;
`
const Col50 = styled(Flex)`
    width: 50%;
    height: 42px;
`

const CsButtonMobile = styled(Flex) <{ isActive: boolean }>`
    width: 100%;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: ${({ theme, isActive }) => isActive ? theme.colors.primary : theme.colors.textSubtle};
    border-bottom: 2px solid ${({ theme, isActive }) => isActive ? theme.colors.primary : "none"};
    font-size: 14px;
    font-weight:${({ isActive }) => isActive ? "600" : "600"};
`